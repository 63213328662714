export const main_coins_medium = {
  INITIAL_DATE: '2022-01-01',
  INITIAL_WALLET: 10000,
  RETURN: 272.48,
  VOLUME: 9404853.84,
  ORDERS: 2752,
  PAID_FEES: 9404.85,
  MIN_LEVEL: 8.25,
  MAXIMUM: 49261.31,
  MINIMUM: 5830.7,
  MAX_DRAWDOWN: 44.64,
  SHARPE: 2.01,
  BETA: 0.51,
  AVG_MONTHLY_RETURN: 4.75,
  TOTAL_MONTHS: 37,
  POSITIVE_MONTHS: 20,
  PERC_POSITIVE_MONTHS: 54.05,
  MONTHLY_RETURNS: [
    -9.16, -0.17, 0.74, -10.33, -3.54, -19.68, 33.18, 7.04, 5.9, 12.19, -2.5,
    -12.22, 32.08, -8.36, 12.37, 2.55, -1.86, -2.89, 4.65, -8.08, -6.51, 35.4,
    0.56, 39.32, 5.33, 32.31, 4.05, -12.9, 5.78, -0.36, 4.28, -9.42, -0.4, 4.5,
    50.69, -10.71, 1.88
  ],
  MIN_ALLOCATION: {
    BTC: 0.0,
    ETH: 0.0,
    BNB: 0.29,
    SOL: 0.0,
    XRP: 0.0,
    USDT: 13.03
  },
  AVG_ALLOCATION: {
    BTC: 11.84,
    ETH: 16.88,
    BNB: 3.26,
    SOL: 12.67,
    XRP: 6.17,
    USDT: 49.2
  },
  MAX_ALLOCATION: {
    BTC: 86.44,
    ETH: 86.46,
    BNB: 38.9,
    SOL: 57.68,
    XRP: 43.27,
    USDT: 99.59
  },
  MAX_DRAWDOWN_PER_YEAR: {
    2022: 44.64,
    2023: 26.59,
    2024: 26.3,
    2025: 16.84
  },
  POSITIVE_MONTHS_PER_YEAR: {
    2022: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 5,
      PERC_POSITIVE_MONTHS: 41.67
    },
    2023: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 7,
      PERC_POSITIVE_MONTHS: 58.33
    },
    2024: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 7,
      PERC_POSITIVE_MONTHS: 58.33
    },
    2025: {
      TOTAL_MONTHS: 1,
      POSITIVE_MONTHS: 1,
      PERC_POSITIVE_MONTHS: 100.0
    }
  },
  LAST_12_MONTHS_RETURN: 73.24,
  LAST_24_MONTHS_RETURN: 232.62,
  AVG_ALLOCATION_PER_YEAR: {
    2022: {
      BTC: 7.43,
      ETH: 23.94,
      BNB: 2.97,
      SOL: 7.41,
      XRP: 5.62,
      USDT: 52.6
    },
    2023: {
      BTC: 13.62,
      ETH: 9.66,
      BNB: 3.08,
      SOL: 18.37,
      XRP: 6.56,
      USDT: 48.69
    },
    2024: {
      BTC: 14.66,
      ETH: 17.52,
      BNB: 3.97,
      SOL: 12.27,
      XRP: 5.66,
      USDT: 45.89
    },
    2025: {
      BTC: 9.6,
      ETH: 10.97,
      BNB: 0.47,
      SOL: 11.88,
      XRP: 13.71,
      USDT: 53.34
    }
  },
  RETURN_PER_YEAR: {
    2022: -8.0,
    2023: 126.72,
    2024: 76.1,
    2025: 1.88
  },
  CHARTS: {
    ALL: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31',
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31',
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31',
        '2024-08-01',
        '2024-08-02',
        '2024-08-03',
        '2024-08-04',
        '2024-08-05',
        '2024-08-06',
        '2024-08-07',
        '2024-08-08',
        '2024-08-09',
        '2024-08-10',
        '2024-08-11',
        '2024-08-12',
        '2024-08-13',
        '2024-08-14',
        '2024-08-15',
        '2024-08-16',
        '2024-08-17',
        '2024-08-18',
        '2024-08-19',
        '2024-08-20',
        '2024-08-21',
        '2024-08-22',
        '2024-08-23',
        '2024-08-24',
        '2024-08-25',
        '2024-08-26',
        '2024-08-27',
        '2024-08-28',
        '2024-08-29',
        '2024-08-30',
        '2024-08-31',
        '2024-09-01',
        '2024-09-02',
        '2024-09-03',
        '2024-09-04',
        '2024-09-05',
        '2024-09-06',
        '2024-09-07',
        '2024-09-08',
        '2024-09-09',
        '2024-09-10',
        '2024-09-11',
        '2024-09-12',
        '2024-09-13',
        '2024-09-14',
        '2024-09-15',
        '2024-09-16',
        '2024-09-17',
        '2024-09-18',
        '2024-09-19',
        '2024-09-20',
        '2024-09-21',
        '2024-09-22',
        '2024-09-23',
        '2024-09-24',
        '2024-09-25',
        '2024-09-26',
        '2024-09-27',
        '2024-09-28',
        '2024-09-29',
        '2024-09-30',
        '2024-10-01',
        '2024-10-02',
        '2024-10-03',
        '2024-10-04',
        '2024-10-05',
        '2024-10-06',
        '2024-10-07',
        '2024-10-08',
        '2024-10-09',
        '2024-10-10',
        '2024-10-11',
        '2024-10-12',
        '2024-10-13',
        '2024-10-14',
        '2024-10-15',
        '2024-10-16',
        '2024-10-17',
        '2024-10-18',
        '2024-10-19',
        '2024-10-20',
        '2024-10-21',
        '2024-10-22',
        '2024-10-23',
        '2024-10-24',
        '2024-10-25',
        '2024-10-26',
        '2024-10-27',
        '2024-10-28',
        '2024-10-29',
        '2024-10-30',
        '2024-10-31',
        '2024-11-01',
        '2024-11-02',
        '2024-11-03',
        '2024-11-04',
        '2024-11-05',
        '2024-11-06',
        '2024-11-07',
        '2024-11-08',
        '2024-11-09',
        '2024-11-10',
        '2024-11-11',
        '2024-11-12',
        '2024-11-13',
        '2024-11-14',
        '2024-11-15',
        '2024-11-16',
        '2024-11-17',
        '2024-11-18',
        '2024-11-19',
        '2024-11-20',
        '2024-11-21',
        '2024-11-22',
        '2024-11-23',
        '2024-11-24',
        '2024-11-25',
        '2024-11-26',
        '2024-11-27',
        '2024-11-28',
        '2024-11-29',
        '2024-11-30',
        '2024-12-01',
        '2024-12-02',
        '2024-12-03',
        '2024-12-04',
        '2024-12-05',
        '2024-12-06',
        '2024-12-07',
        '2024-12-08',
        '2024-12-09',
        '2024-12-10',
        '2024-12-11',
        '2024-12-12',
        '2024-12-13',
        '2024-12-14',
        '2024-12-15',
        '2024-12-16',
        '2024-12-17',
        '2024-12-18',
        '2024-12-19',
        '2024-12-20',
        '2024-12-21',
        '2024-12-22',
        '2024-12-23',
        '2024-12-24',
        '2024-12-25',
        '2024-12-26',
        '2024-12-27',
        '2024-12-28',
        '2024-12-29',
        '2024-12-30',
        '2024-12-31',
        '2025-01-01',
        '2025-01-02',
        '2025-01-03',
        '2025-01-04',
        '2025-01-05',
        '2025-01-06',
        '2025-01-07',
        '2025-01-08',
        '2025-01-09',
        '2025-01-10',
        '2025-01-11',
        '2025-01-12',
        '2025-01-13',
        '2025-01-14',
        '2025-01-15',
        '2025-01-16',
        '2025-01-17',
        '2025-01-18',
        '2025-01-19',
        '2025-01-20',
        '2025-01-21',
        '2025-01-22',
        '2025-01-23',
        '2025-01-24',
        '2025-01-25',
        '2025-01-26',
        '2025-01-27',
        '2025-01-28',
        '2025-01-29',
        '2025-01-30',
        '2025-01-31',
        '2025-02-01'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14, 3574.62,
        3592.22, 3613.05, 3608.44, 3640.8, 3643.22, 3669.29, 3664.53, 3670.33,
        3722.62, 3770.93, 3798.69, 4092.42, 4294.83, 4541.61, 4524.59, 4584.01,
        4594.07, 4501.77, 4557.9, 4888.92, 4924.54, 4906.53, 4960.38, 4923.64,
        4961.86, 4977.35, 4978.31, 4971.69, 5142.25, 4918.07, 5015.87, 5131.13,
        5096.87, 5067.62, 5063.7, 4972.1, 4950.43, 5026.01, 4961.11, 4709.86,
        4667.38, 4722.19, 4715.7, 4682.35, 4800.77, 5225.7, 5191.77, 5339.85,
        5328.63, 5299.17, 5345.34, 5276.01, 5214.69, 5163.93, 5002.47, 5002.22,
        5079.62, 5080.85, 5011.44, 5091.63, 5076.27, 4829.61, 4826.37, 4853.85,
        4853.99, 4783.02, 4717.35, 4401.76, 4348.46, 4399.59, 4695.77, 5236.42,
        5344.01, 5271.04, 5405.29, 5953.08, 5822.64, 6082.81, 6048.04, 6065.13,
        5887.28, 6104.71, 5906.5, 5938.8, 6067.1, 5863.65, 5889.26, 6142.65,
        6036.44, 6181.78, 6170.45, 6081.28, 6015.11, 6098.9, 6096.31, 6060.09,
        6048.86, 6044.92, 6138.74, 6403.28, 6548.2, 6456.8, 6555.19, 6585.32,
        6567.07, 6562.75, 6383.44, 6571.37, 6291.24, 6112.28, 5899.58, 6026.64,
        5970.05, 5942.39, 6108.85, 6122.92, 6358.1, 6349.48, 6318.17, 6356.7,
        6062.71, 6217.83, 6297.93, 6227.13, 6402.35, 6239.08, 6218.33, 5983.32,
        5967.42, 5987.06, 5821.21, 5773.8, 5818.2, 5820.54, 5909.13, 5841.66,
        5914.23, 5818.59, 5814.14, 5856.58, 5788.96, 5814.33, 5884.19, 5701.06,
        5723.97, 5774.07, 5799.45, 6077.95, 5991.14, 6001.81, 5881.83, 5816.26,
        5898.9, 5857.49, 5892.66, 5563.05, 5875.04, 5685.34, 5743.43, 5732.25,
        5598.63, 5603.1, 5610.48, 5591.02, 5416.84, 5545.44, 5705.81, 5749.0,
        5712.68, 5789.0, 6104.57, 6515.91, 6475.5, 6630.51, 6602.63, 6599.5,
        6540.36, 6618.01, 6514.05, 6579.69, 6594.27, 6616.11, 6632.05, 6733.62,
        6668.55, 6585.66, 6527.32, 6556.92, 6539.7, 6528.78, 6566.28, 6617.61,
        6570.9, 6774.4, 6540.37, 6552.89, 6560.59, 6514.23, 6452.11, 6463.6,
        6450.88, 6474.91, 6449.59, 6494.06, 6309.65, 6319.43, 6377.75, 6318.53,
        6337.94, 6351.0, 6308.51, 6320.19, 6329.72, 6315.0, 6325.66, 6287.22,
        6294.36, 6296.4, 6313.96, 6440.93, 6405.0, 6375.37, 6368.78, 6367.04,
        6343.87, 6369.53, 6314.48, 6255.29, 5799.02, 5641.22, 5637.33, 5670.97,
        5654.2, 5584.1, 5724.31, 5651.55, 5637.33, 5632.18, 5644.96, 5638.93,
        5980.12, 5894.16, 5637.32, 5574.65, 5595.68, 5614.95, 5576.96, 5575.51,
        5571.17, 5695.75, 5605.24, 5605.85, 5593.7, 5441.44, 5619.16, 5678.04,
        5755.72, 5793.62, 5746.82, 5732.1, 5814.35, 5883.0, 5862.77, 5753.31,
        5752.09, 5747.29, 5731.85, 5686.63, 5659.75, 5697.94, 5844.39, 5817.61,
        5844.24, 6057.62, 5962.0, 5929.47, 6004.89, 5936.19, 6052.16, 6047.79,
        6040.86, 5968.96, 5938.93, 5795.31, 5789.02, 5805.21, 5809.44, 5864.12,
        6168.65, 6148.6, 6132.73, 6198.95, 6417.92, 6490.41, 6499.56, 7208.6,
        7390.29, 7487.84, 7405.89, 7314.75, 7379.2, 7508.6, 7458.0, 7477.59,
        7633.62, 7527.28, 7492.53, 7607.6, 7566.79, 7598.02, 7634.01, 7742.54,
        7908.06, 8081.82, 7982.33, 8039.4, 7890.46, 7704.53, 8158.62, 7836.43,
        7907.23, 7898.83, 8001.46, 8097.85, 7871.05, 8102.2, 8071.71, 8153.32,
        8162.6, 8147.07, 8023.73, 8192.04, 8176.8, 8155.89, 8374.38, 8513.98,
        8667.97, 9076.89, 9582.07, 9469.87, 9333.38, 9576.69, 9498.72, 9473.47,
        8933.15, 8921.01, 9295.81, 9319.32, 9100.81, 9154.93, 9026.53, 9198.16,
        9159.77, 9436.29, 9509.38, 9477.05, 9465.33, 9299.04, 9451.11, 9189.27,
        9374.01, 9223.04, 9035.87, 9116.29, 9143.38, 9418.61, 9732.73, 9249.79,
        9589.13, 9513.27, 9494.06, 9490.64, 10169.43, 10008.02, 10148.38,
        10039.61, 9197.92, 9301.93, 9146.22, 9223.46, 9348.52, 9243.83, 8947.95,
        9019.87, 9040.31, 9008.25, 8618.66, 8539.88, 8628.01, 8645.38, 9067.3,
        9121.23, 9051.66, 9327.99, 9374.87, 9229.53, 9288.18, 9347.78, 9310.73,
        9211.26, 9196.79, 9347.22, 9555.08, 9795.66, 10228.42, 10348.13,
        10415.23, 10823.12, 10715.02, 11206.38, 11174.35, 11246.5, 11188.65,
        11303.17, 11237.53, 11287.78, 11145.51, 11133.59, 10989.05, 11155.35,
        11186.81, 11826.79, 12309.97, 13258.11, 13247.1, 13521.04, 13346.85,
        13594.03, 14697.88, 13717.48, 14262.42, 14518.96, 14735.31, 14792.2,
        14803.79, 15674.15, 15397.39, 15784.65, 15479.0, 14952.16, 14358.61,
        14762.55, 14646.58, 13552.17, 14644.4, 14148.96, 13636.12, 14009.55,
        14486.3, 15213.04, 15199.89, 15024.83, 15329.45, 15104.42, 15085.58,
        15355.85, 15083.6, 14240.66, 14310.77, 14699.6, 14632.84, 14941.71,
        14972.87, 15560.55, 15000.05, 15260.21, 15178.69, 14508.39, 13911.23,
        14135.58, 13730.89, 13800.7, 13347.91, 13739.5, 13850.44, 13980.49,
        14070.69, 14502.9, 14358.99, 13868.51, 13978.2, 13809.21, 13687.63,
        13664.05, 13823.94, 13109.87, 12523.67, 12836.86, 13584.29, 13764.21,
        13817.45, 13738.62, 13599.12, 13252.94, 13692.06, 13167.04, 13179.15,
        13269.16, 13581.61, 13351.99, 14305.65, 14154.76, 14447.43, 14482.14,
        14359.15, 15057.42, 15194.04, 14960.16, 14645.38, 14883.73, 14957.51,
        14834.39, 15057.6, 14846.38, 14643.99, 14781.36, 14607.1, 14662.47,
        14697.64, 14938.79, 15267.41, 15393.67, 15340.91, 15037.57, 15002.76,
        15066.85, 15036.91, 14574.79, 14788.05, 14474.35, 14283.51, 14323.75,
        14444.06, 14422.42, 14124.69, 14028.63, 14061.93, 13881.28, 13916.06,
        13773.74, 12992.85, 13395.52, 13182.31, 13337.11, 13061.88, 13192.14,
        13609.95, 13625.98, 13434.47, 13050.63, 12557.92, 12251.16, 12572.55,
        12202.35, 12264.87, 12535.66, 12486.92, 12406.4, 12504.51, 12866.4,
        13168.64, 13922.09, 13981.22, 13905.98, 13854.67, 14436.53, 14521.51,
        14715.82, 14634.25, 14262.74, 14126.43, 14203.45, 14697.21, 14847.37,
        14715.43, 14546.05, 14328.52, 14059.78, 14104.0, 13282.15, 13118.53,
        12704.4, 11834.59, 12160.11, 11961.85, 13486.83, 13147.47, 13183.53,
        12765.02, 12777.06, 13108.86, 12762.85, 12466.72, 12758.65, 12833.86,
        12841.61, 12818.03, 12845.5, 13236.81, 13076.39, 13866.12, 13811.81,
        13952.03, 13625.53, 12875.57, 12808.17, 12811.75, 12817.27, 12771.07,
        12400.0, 12841.61, 12520.3, 12577.86, 12139.41, 11607.0, 11674.08,
        11798.71, 12379.88, 12466.39, 12434.41, 12580.53, 13113.81, 12983.76,
        12858.99, 12543.03, 13034.19, 13130.42, 13618.39, 13670.37, 13681.2,
        13822.1, 13678.75, 13973.21, 13688.06, 14082.79, 14252.35, 14211.25,
        14210.03, 13735.66, 13148.43, 13166.54, 13175.96, 13461.12, 13423.55,
        13548.89, 13516.93, 13452.87, 13154.92, 13014.28, 13512.42, 13695.55,
        13596.32, 14378.71, 14442.24, 14667.78, 14592.92, 14757.71, 14784.63,
        14959.29, 14665.85, 14653.5, 14382.82, 14752.99, 14416.02, 14525.41,
        14740.05, 15137.67, 15721.51, 15664.69, 15249.82, 15024.8, 15023.5,
        14903.63, 14695.91, 15058.12, 16365.97, 16416.49, 16524.06, 16556.68,
        17442.52, 19036.16, 19168.73, 19411.75, 18962.75, 19734.76, 19544.43,
        19377.31, 19721.78, 19868.52, 20373.6, 21281.38, 21340.09, 21124.73,
        21204.34, 20426.39, 19865.26, 20781.88, 20696.26, 21051.31, 20872.46,
        21109.7, 20635.73, 20797.09, 21242.13, 20899.06, 21746.15, 21549.49,
        21684.7, 20984.52, 20955.4, 21898.84, 21733.16, 21902.64, 21878.92,
        22346.2, 22895.51, 22929.26, 21886.45, 21289.0, 21088.36, 21019.89,
        20673.68, 20596.74, 21372.49, 21379.87, 20733.08, 20433.58, 20625.86,
        20151.78, 20075.92, 20228.26, 20512.6, 20968.73, 21268.48, 21294.63,
        21354.78, 22072.42, 21023.46, 20618.19, 20004.85, 20504.97, 20467.35,
        20330.84, 20425.41, 20921.31, 21575.1, 21602.1, 22591.38, 22565.61,
        22375.78, 22417.75, 22934.46, 22473.75, 22497.99, 22670.75, 22735.73,
        22574.02, 21991.33, 21910.94, 22392.68, 22739.4, 22102.01
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32, 3253.85, 3267.01,
        3305.31, 3298.73, 3409.23, 3402.27, 3453.75, 3435.88, 3453.24, 3593.14,
        3629.97, 3670.4, 3869.56, 3953.38, 4219.16, 4236.81, 4297.89, 4282.03,
        4165.63, 4220.36, 4493.58, 4431.07, 4430.38, 4427.01, 4249.36, 4314.07,
        4357.13, 4333.08, 4256.27, 4469.31, 4254.25, 4325.96, 4455.35, 4503.64,
        4535.11, 4569.91, 4445.5, 4441.65, 4529.94, 4490.74, 4183.61, 4103.41,
        4178.96, 4116.61, 4057.42, 4228.62, 4531.21, 4477.65, 4620.85, 4597.03,
        4585.0, 4605.05, 4496.83, 4446.33, 4480.38, 4365.56, 4329.74, 4443.52,
        4436.86, 4386.16, 4506.05, 4484.98, 4262.43, 4247.7, 4258.68, 4268.99,
        4225.2, 4204.29, 3896.76, 3873.94, 3970.62, 4255.21, 4570.72, 4628.12,
        4483.65, 4550.01, 4820.67, 4812.48, 4909.87, 4753.51, 4879.35, 4696.99,
        4919.44, 4741.98, 4727.2, 4831.25, 4650.12, 4816.02, 4870.54, 4854.08,
        4967.98, 4959.64, 4878.4, 4913.49, 5078.55, 5195.41, 5085.18, 5080.83,
        5034.91, 5075.33, 5179.08, 5152.32, 5203.73, 5460.43, 5725.35, 5699.48,
        5784.3, 5657.13, 5691.98, 5347.87, 5285.31, 5019.0, 5106.95, 5064.21,
        5002.6, 5080.23, 5073.14, 5182.84, 5152.24, 5177.94, 5147.64, 4967.02,
        5097.42, 5186.97, 5090.24, 5409.3, 5155.78, 5188.71, 5024.38, 5015.9,
        5011.71, 4868.25, 4902.99, 4901.3, 4896.38, 4959.38, 4959.97, 4951.59,
        4920.15, 4929.88, 4946.21, 4909.9, 4947.02, 5044.29, 4894.64, 4916.13,
        4967.83, 4978.11, 5186.62, 5147.21, 5180.31, 5085.16, 5079.8, 5185.15,
        5144.87, 5172.72, 4915.83, 5121.07, 4977.16, 5030.89, 5001.0, 4770.89,
        4770.81, 4741.27, 4719.18, 4488.27, 4530.46, 4679.3, 4707.35, 4685.29,
        4714.96, 4855.9, 5149.9, 5088.12, 5142.11, 5100.18, 5168.77, 5056.76,
        5130.31, 4971.95, 5036.65, 5262.43, 5230.2, 5282.73, 5318.87, 5281.42,
        5192.25, 5082.92, 5080.83, 5062.06, 5077.81, 5103.85, 5111.24, 5090.63,
        5416.47, 5240.18, 5246.14, 5259.16, 5194.97, 5171.87, 5136.13, 5153.32,
        5149.52, 5125.25, 5123.51, 5026.21, 5058.77, 5093.88, 5050.04, 5103.13,
        5113.26, 5048.21, 5049.74, 5035.92, 5012.2, 4999.17, 4974.12, 5000.61,
        4978.31, 4971.78, 5052.46, 5047.48, 5036.71, 5023.13, 5031.4, 5002.76,
        5020.79, 4973.49, 4929.53, 4611.54, 4521.98, 4533.02, 4580.27, 4534.81,
        4405.79, 4559.53, 4500.45, 4499.06, 4484.35, 4508.51, 4490.94, 4705.74,
        4628.33, 4487.37, 4424.89, 4452.44, 4449.62, 4418.44, 4438.65, 4438.29,
        4489.66, 4449.94, 4448.82, 4405.22, 4210.62, 4344.34, 4370.9, 4433.92,
        4486.97, 4450.18, 4408.56, 4466.72, 4466.54, 4414.82, 4313.8, 4338.03,
        4326.58, 4323.18, 4316.87, 4319.4, 4347.47, 4489.03, 4534.65, 4556.14,
        4718.28, 4519.04, 4508.89, 4484.43, 4395.16, 4474.58, 4447.96, 4437.02,
        4289.7, 4265.62, 4258.2, 4188.86, 4214.87, 4233.63, 4230.36, 4357.59,
        4249.43, 4256.02, 4254.96, 4374.87, 4435.14, 4533.42, 4856.53, 4878.91,
        4876.68, 4916.15, 4833.76, 4833.41, 4910.3, 4921.73, 4920.86, 5014.27,
        4885.22, 4981.2, 5053.58, 5129.09, 5176.74, 5112.6, 5144.1, 5696.3,
        5662.6, 5562.77, 5605.77, 5599.95, 5404.45, 5584.01, 5352.96, 5338.57,
        5323.9, 5388.21, 5505.81, 5283.73, 5616.79, 5619.65, 5641.46, 5665.8,
        5649.08, 5495.63, 5573.95, 5519.35, 5558.25, 5672.96, 5869.85, 5997.91,
        6060.75, 6206.55, 6076.31, 6391.93, 6430.09, 6401.69, 6423.4, 6047.75,
        5952.07, 6149.49, 6297.08, 6068.72, 6063.11, 6035.75, 6019.02, 5924.06,
        5962.98, 6106.12, 6291.75, 6246.7, 6146.52, 6215.5, 6060.42, 6432.49,
        6412.01, 6206.42, 6223.28, 6187.78, 6349.46, 6416.99, 5999.06, 6174.8,
        6114.28, 6086.26, 6028.98, 6358.77, 6367.67, 6929.92, 7114.41, 6796.64,
        7013.08, 6824.49, 6838.48, 7054.92, 6878.27, 6720.28, 6775.7, 6723.87,
        6697.56, 6312.5, 6056.24, 6042.55, 6042.63, 6169.39, 6165.18, 6118.75,
        6287.5, 6433.12, 6217.65, 6248.39, 6280.59, 6252.06, 6224.21, 6241.81,
        6478.79, 6597.93, 6594.48, 6787.44, 6789.54, 6801.48, 7222.27, 7170.07,
        7540.39, 7652.97, 7586.69, 7577.14, 7834.11, 8055.84, 8147.07, 7981.54,
        8127.59, 7950.83, 8128.9, 8439.08, 8663.72, 8834.19, 9086.29, 9038.09,
        9338.94, 9259.49, 9449.42, 9815.72, 9538.34, 10373.19, 10491.76,
        10545.81, 10611.63, 10401.14, 11065.12, 10744.14, 10834.77, 10547.79,
        10145.72, 9678.37, 9916.38, 9581.32, 8735.28, 9576.28, 9474.93, 8941.77,
        9186.32, 9361.6, 9824.6, 9806.3, 9561.84, 9702.04, 9580.17, 9538.04,
        9873.7, 9532.08, 8972.08, 9027.08, 9009.99, 9016.42, 9189.85, 9305.73,
        10122.43, 9571.49, 9624.56, 9535.94, 8756.12, 8211.71, 8586.76, 8505.53,
        8415.15, 8183.9, 8351.9, 8350.51, 8544.08, 8583.82, 8755.68, 8746.49,
        8520.15, 8595.22, 8520.69, 8827.49, 8951.05, 8746.49, 8188.04, 8077.73,
        8153.5, 8448.88, 8452.69, 8519.57, 8383.02, 8260.2, 8076.7, 8308.35,
        7903.65, 7932.02, 7975.48, 7998.06, 7860.2, 8196.85, 7996.07, 8408.04,
        8481.3, 8370.13, 9825.17, 10374.22, 10186.93, 10366.6, 10166.34,
        10167.1, 10440.21, 10575.67, 10508.05, 10280.48, 10193.71, 10256.11,
        10396.14, 10288.53, 10278.23, 10375.82, 10474.75, 10369.13, 10051.22,
        10001.26, 10085.71, 9995.65, 9522.26, 9715.12, 9471.09, 9479.88,
        9712.75, 9870.47, 9585.93, 9467.01, 9675.7, 9573.75, 9584.74, 9527.43,
        9317.73, 9065.46, 9257.15, 9195.13, 9388.04, 9178.76, 9185.14, 9386.39,
        9367.04, 9315.77, 8982.01, 8508.97, 8098.89, 8335.63, 7990.79, 8227.7,
        8322.98, 8435.11, 8440.66, 8506.76, 8682.59, 8829.4, 9446.48, 9326.59,
        9235.49, 9339.97, 9543.32, 9559.25, 9611.73, 9379.67, 9472.72, 9056.16,
        8609.48, 8921.26, 8909.1, 8867.05, 9056.53, 8919.58, 8822.46, 8729.62,
        8102.0, 7914.01, 7404.79, 6672.02, 6733.53, 6410.0, 7365.34, 7068.11,
        7089.79, 6984.23, 7334.47, 7381.17, 7263.15, 7008.41, 7061.31, 7096.62,
        7199.2, 7134.46, 7037.07, 7156.17, 7138.73, 7507.31, 7485.39, 7537.73,
        7308.03, 6692.7, 6888.69, 6866.28, 6871.5, 6846.69, 6545.62, 6923.23,
        6674.21, 6699.01, 6451.37, 6010.18, 6160.75, 6238.62, 6412.44, 6502.75,
        6365.19, 6422.32, 6655.73, 6574.48, 6382.68, 6214.09, 6359.21, 6364.54,
        6724.28, 6938.03, 7006.88, 7045.08, 7198.52, 7252.49, 6997.41, 7175.04,
        7359.45, 7276.48, 7224.31, 7078.96, 6675.13, 6479.79, 6398.08, 6585.31,
        6577.39, 6615.23, 6573.85, 6642.02, 6456.23, 6468.47, 6635.55, 6765.95,
        6710.98, 7207.93, 7057.5, 7132.07, 7106.96, 7171.65, 7210.64, 7473.48,
        7282.77, 7169.93, 6829.31, 6890.81, 6703.06, 6764.46, 6839.86, 6987.57,
        7172.11, 7235.1, 6859.26, 6836.44, 6777.19, 6692.16, 6545.3, 6623.06,
        7440.53, 7837.95, 8048.65, 8526.04, 8662.03, 9090.29, 8945.04, 8649.62,
        8399.91, 8433.89, 8522.29, 8345.73, 8566.14, 8412.61, 8362.86, 9170.64,
        8998.73, 9264.57, 9136.61, 9387.74, 9045.65, 9971.9, 9772.57, 9781.68,
        10088.47, 10122.36, 9833.52, 9880.84, 10450.93, 10345.71, 10945.84,
        10865.21, 10908.84, 10103.26, 9883.01, 10434.64, 10561.72, 10599.04,
        10505.03, 10579.34, 10965.14, 10563.35, 10012.3, 9410.21, 9444.46,
        9059.55, 8946.55, 9410.95, 9500.57, 9454.25, 9088.01, 9033.28, 9277.46,
        9086.59, 9136.89, 9085.07, 9144.23, 9359.07, 9836.66, 9956.1, 9915.93,
        10006.23, 9239.63, 9066.74, 8770.51, 8904.83, 8945.77, 8815.07, 8519.49,
        8800.9, 9332.33, 8970.12, 9439.59, 8923.43, 8885.87, 9026.88, 9068.37,
        8810.36, 9037.41, 9046.58, 9070.21, 8944.38, 8625.55, 8389.46, 8520.83,
        8870.17, 9028.6
      ],
      BNB: [
        10000.0, 10275.72, 10387.17, 10023.47, 9974.58, 9305.83, 9317.56,
        8760.27, 8506.07, 8609.7, 8273.37, 9026.21, 9485.73, 9325.38, 9587.41,
        9696.91, 9763.4, 9256.94, 9247.16, 9159.17, 8756.35, 7465.78, 6941.73,
        7260.46, 7195.94, 7471.64, 7291.75, 7573.33, 7508.81, 7622.21, 7356.27,
        7354.32, 7543.99, 7217.44, 7217.44, 7737.58, 8101.3, 8046.54, 8502.15,
        8023.08, 8277.28, 8201.02, 7706.29, 7884.24, 7815.8, 7888.15, 8410.25,
        8371.14, 7919.44, 7774.74, 7800.16, 7524.44, 7104.02, 7252.64, 7254.59,
        6937.82, 7326.94, 7303.49, 7102.07, 7692.61, 7970.28, 8007.43, 7868.6,
        7338.68, 7520.53, 7442.32, 7469.69, 7436.45, 7661.32, 7280.02, 7303.49,
        7332.81, 7082.52, 7227.22, 7276.11, 7487.29, 7653.5, 7735.63, 7802.11,
        7686.74, 7755.18, 7940.95, 7948.76, 8093.47, 8025.03, 8103.25, 8388.73,
        8498.24, 8470.87, 8664.45, 8437.62, 8705.52, 8629.25, 8818.92, 8756.35,
        8779.82, 8275.32, 8537.35, 8246.0, 8289.01, 8214.7, 7747.36, 8066.1,
        8283.15, 8103.25, 8142.35, 8152.13, 8093.47, 8169.73, 8228.4, 8201.02,
        7915.53, 7960.5, 7903.79, 7831.44, 7894.01, 7545.96, 7622.21, 7983.96,
        7690.66, 7538.13, 7598.74, 7600.71, 7487.29, 7862.73, 7356.27, 7430.59,
        7088.39, 6978.88, 6028.55, 6138.06, 5295.27, 5215.1, 5784.12, 5766.52,
        6052.01, 5840.83, 5960.11, 5725.46, 5958.16, 5917.09, 6120.46, 6224.09,
        6261.25, 6415.72, 6437.23, 6063.74, 5934.69, 6007.04, 5981.62, 6296.44,
        6300.35, 5883.84, 6024.64, 5827.14, 5897.54, 5860.39, 5789.99, 5658.97,
        5668.76, 5658.97, 5621.82, 5342.2, 5039.11, 4301.91, 4266.72, 4518.96,
        4098.56, 4227.61, 3887.37, 4211.97, 4219.79, 4342.98, 4227.61, 4470.08,
        4710.6, 4669.53, 4608.91, 4583.5, 4440.75, 4323.43, 4170.91, 4247.16,
        4280.41, 4274.54, 4501.36, 4560.03, 4665.63, 4710.6, 4732.11, 4751.66,
        4579.58, 4417.29, 4344.94, 4429.01, 4636.29, 4693.0, 4904.18, 4892.45,
        5078.22, 5281.58, 5043.02, 5172.08, 5164.25, 5066.48, 5117.32, 4898.32,
        4829.88, 5263.98, 5434.1, 5809.54, 5658.97, 5543.61, 5531.87, 5565.12,
        5864.29, 6079.39, 6184.98, 6198.68, 6341.41, 6337.51, 6378.57, 6400.08,
        6317.95, 6353.15, 6360.97, 6233.86, 6212.36, 6179.11, 5977.71, 5915.14,
        5482.99, 5520.14, 5909.27, 5866.24, 5844.74, 5799.76, 5897.54, 5529.92,
        5408.69, 5461.47, 5561.21, 5531.87, 5482.99, 5434.1, 5426.29, 5426.29,
        5424.32, 5396.95, 5187.72, 5434.1, 5494.72, 5727.42, 5817.36, 5739.14,
        5705.91, 5420.42, 5469.3, 5281.58, 5365.67, 5461.47, 5207.27, 5305.05,
        5222.92, 5136.88, 5371.53, 5404.77, 5400.86, 5334.37, 5371.53, 5332.42,
        5502.54, 5512.32, 5514.27, 5543.61, 5584.67, 5588.59, 5782.17, 5746.97,
        5555.34, 5526.01, 5402.82, 5426.29, 5326.55, 5297.22, 5295.27, 5340.24,
        5262.02, 5248.34, 5334.37, 5379.35, 5342.2, 5314.82, 5260.07, 5275.72,
        5285.49, 5396.95, 5350.02, 5578.8, 5694.17, 5592.49, 5838.87, 5917.09,
        6106.76, 6423.55, 6359.01, 6253.43, 6501.76, 6888.93, 6840.05, 6824.4,
        6507.63, 6175.21, 5263.98, 5979.66, 5647.24, 5533.82, 5406.72, 5391.09,
        5387.17, 5316.77, 5250.29, 5344.15, 5338.29, 5170.12, 4966.76, 5211.19,
        5795.86, 5877.98, 5868.21, 6069.61, 6138.06, 5727.42, 5809.54, 5876.03,
        5725.46, 5707.86, 5684.39, 5696.13, 5635.51, 5647.24, 5555.34, 5664.84,
        5590.54, 5629.64, 5582.72, 5410.64, 5312.87, 5238.57, 5029.33, 4501.36,
        4696.91, 4913.96, 4698.86, 4908.1, 4822.06, 4802.5, 4812.28, 4779.03,
        4749.71, 4743.84, 4814.23, 4759.48, 4804.46, 4794.68, 4816.2, 4786.86,
        4810.33, 4810.33, 5039.11, 5023.47, 5080.17, 5103.64, 5314.82, 5346.11,
        5412.59, 5482.99, 5610.09, 5731.32, 5967.93, 5921.01, 5860.39, 5881.89,
        5676.57, 5746.97, 5928.82, 5889.71, 5897.54, 5979.66, 5911.23, 5962.06,
        5954.24, 6005.08, 5960.11, 6220.18, 5983.58, 6112.63, 6200.63, 6386.39,
        6464.61, 6472.43, 6415.72, 6386.39, 6499.8, 6405.95, 5948.38, 5967.93,
        6077.43, 6181.08, 5696.13, 5788.04, 6179.11, 6010.95, 6132.19, 6184.98,
        6120.46, 6134.14, 6083.3, 6077.43, 6032.46, 5885.81, 5897.54, 6028.55,
        5956.2, 5919.04, 5905.36, 5870.16, 5655.07, 5633.56, 5651.16, 5625.74,
        5623.77, 5619.87, 5400.86, 5416.5, 5355.89, 5563.16, 6046.15, 6044.19,
        5973.79, 6468.52, 6583.88, 6493.93, 6619.08, 6576.07, 6558.47, 6286.66,
        6419.63, 6290.58, 6292.53, 6427.45, 6036.37, 6104.81, 6140.01, 6173.25,
        6198.68, 6165.43, 6118.5, 6008.99, 6081.34, 6151.74, 6108.73, 6079.39,
        6073.53, 6122.41, 6169.34, 6341.41, 6245.6, 6341.41, 6443.1, 6527.18,
        6838.09, 6662.1, 6705.12, 6349.23, 6204.54, 6269.06, 6495.9, 6462.65,
        6480.25, 6591.71, 6450.92, 6503.72, 6335.55, 6302.31, 6603.45, 6425.5,
        6312.08, 6388.35, 6321.86, 6396.17, 6310.13, 6310.13, 6128.28, 6091.13,
        6165.43, 5993.36, 6008.99, 6093.08, 6098.94, 6138.06, 6083.3, 6132.19,
        6069.61, 6042.24, 6065.71, 5997.26, 6050.06, 6128.28, 5977.71, 5952.29,
        5999.22, 5995.31, 6136.1, 6079.39, 6096.99, 5987.49, 5977.71, 6012.91,
        5989.44, 5983.58, 5375.44, 5516.24, 5048.88, 5144.7, 5105.6, 4679.31,
        4597.18, 4513.1, 4720.38, 4620.65, 4624.56, 4677.36, 4802.5, 4757.53,
        4745.8, 4781.0, 4884.63, 4696.91, 4784.9, 4616.74, 4675.4, 4618.7,
        4648.03, 4515.06, 4558.08, 4712.55, 4843.57, 4825.97, 4818.15, 4747.75,
        4673.45, 4569.81, 4605.01, 4616.74, 4581.54, 4767.3, 4865.08, 4767.3,
        4974.58, 4816.2, 4917.87, 4782.95, 4765.35, 4704.73, 4714.51, 4749.71,
        4763.4, 4728.2, 4730.15, 4663.67, 4648.03, 4677.36, 4698.86, 4736.02,
        4736.02, 4728.2, 4724.28, 4818.15, 4718.42, 4714.51, 4722.33, 4753.62,
        4753.62, 4730.15, 4796.63, 4767.3, 4716.46, 4677.36, 4702.78, 4698.86,
        4700.82, 4634.33, 4534.61, 4323.43, 4227.61, 4235.43, 4243.26, 4123.97,
        4080.96, 4227.61, 4254.99, 4262.81, 4231.52, 4268.68, 4272.59, 4440.75,
        4364.49, 4243.26, 4168.94, 4190.46, 4186.54, 4202.19, 4192.41, 4206.11,
        4249.12, 4194.37, 4194.37, 4159.17, 4032.07, 4139.62, 4159.17, 4159.17,
        4196.32, 4204.14, 4233.48, 4237.39, 4245.21, 4182.64, 4122.02, 4131.79,
        4110.29, 4102.46, 4104.42, 4137.66, 4141.57, 4204.14, 4204.14, 4206.11,
        4282.36, 4194.37, 4174.81, 4174.81, 4125.93, 4178.73, 4149.39, 4129.84,
        4026.21, 4082.91, 4034.02, 4014.47, 4028.16, 4037.94, 4094.64, 4208.06,
        4131.79, 4122.02, 4112.24, 4159.17, 4200.24, 4260.86, 4495.5, 4436.84,
        4352.76, 4376.23, 4376.23, 4411.42, 4454.44, 4460.31, 4423.16, 4440.75,
        4509.19, 4503.33, 4649.98, 4779.03, 4984.35, 4812.28, 4820.1, 4855.3,
        4913.96, 4910.05, 4859.21, 4722.33, 4739.93, 4943.3, 4749.71, 4784.9,
        4775.13, 4782.95, 4972.63, 4399.69, 4620.65, 4573.71, 4550.26, 4565.9,
        4544.39, 4429.01, 4487.68, 4446.61, 4444.66, 4462.26, 4470.08, 4470.08,
        4534.61, 4507.23, 4495.5, 4540.48, 4659.76, 4675.4, 4687.13, 4786.86,
        4986.31, 4955.03, 4960.9, 4800.55, 4792.73, 4714.51, 4700.82, 4929.6,
        5070.4, 5307.0, 5320.69, 5314.82, 5156.43, 5228.78, 5750.88, 6212.36,
        6360.97, 6079.39, 6169.34, 6077.43, 6132.19, 6098.94, 6190.85, 6290.58,
        6188.89, 6010.95, 5895.58, 5981.62, 5877.98, 5995.31, 6008.99, 5772.39,
        5915.14, 5930.78, 6184.98, 6173.25, 6077.43, 6112.63, 6126.32, 6196.71,
        6253.43, 5989.44, 5813.46, 5725.46, 5713.72, 5909.27, 5979.66, 5950.33,
        6063.74, 6052.01, 5891.67, 5860.39, 5891.67, 5866.24, 5977.71, 5874.07,
        5932.74, 6022.68, 6239.73, 6325.78, 6327.73, 6272.98, 6421.58, 6347.28,
        6527.18, 6869.38, 7029.72, 6902.62, 6908.49, 6908.49, 6935.87, 7332.81,
        7524.44, 7319.12, 7469.69, 7631.99, 7851.0, 7743.45, 8056.31, 7747.36,
        7944.86, 7995.7, 8114.98, 8193.2, 7665.23, 8341.8, 9184.59, 9374.26,
        9544.39, 10179.9, 10211.19, 10492.77, 12031.68, 11808.77, 11937.82,
        11460.7, 11271.03, 10827.14, 9960.9, 10874.07, 10817.36, 10590.54,
        10915.14, 11157.61, 11509.58, 11351.2, 11247.56, 11439.18, 12088.39,
        11808.77, 11845.92, 11300.35, 10907.31, 11012.91, 11376.61, 11353.15,
        11499.8, 11380.53, 11533.05, 11396.17, 11888.93, 11857.65, 11628.86,
        10850.61, 11071.57, 10895.58, 10574.89, 10520.14, 10774.34, 10885.81,
        11122.41, 11345.33, 11828.32, 11912.4, 11853.74, 11990.62, 11699.25,
        11624.95, 11742.28, 11626.9, 11298.4, 10944.46, 11003.13, 11482.21,
        11441.15, 11525.23, 11552.6, 11366.83, 11493.93, 11707.08, 11458.75,
        11589.75, 11638.64, 11576.07, 11069.61, 11431.36, 11122.41, 11349.24,
        11302.31, 11255.38, 11578.02, 12090.34, 12025.82, 11693.4, 11773.57,
        11759.88, 11750.1, 11808.77, 11785.3, 11664.06, 11634.73, 11626.9,
        11754.01, 11773.57, 12280.02, 13343.77, 13605.79, 13916.7, 13394.6,
        13349.63, 13167.78, 12086.43, 11800.94, 12127.49, 11765.74, 11763.78,
        11896.75, 11941.73, 11853.74, 11523.27, 11695.35, 11572.15, 11462.65,
        11542.83, 11341.41, 11030.51, 11288.62, 11198.68, 11345.33, 11093.08,
        11132.19, 11433.32, 11308.18, 11339.46, 10893.63, 10256.16, 9708.65,
        10332.42, 9636.29, 9994.13, 10082.13, 10240.52, 10263.98, 10420.42,
        10434.1, 10651.16, 11380.53, 11194.76, 11130.23, 11188.89, 11605.4,
        11601.48, 11742.28, 11560.42, 11392.26, 11216.26, 11186.94, 11378.57,
        11419.63, 11386.39, 11290.58, 11388.35, 11314.04, 11259.29, 10606.18,
        10322.65, 9849.43, 9139.62, 9526.79, 9309.74, 10148.62, 9958.93,
        10238.57, 9910.05, 10058.67, 10258.12, 10248.34, 10170.12, 10168.17,
        10586.62, 10486.9, 10928.82, 11218.23, 11143.92, 11468.52, 11587.8,
        11286.66, 11302.31, 10727.42, 10383.27, 10508.41, 10484.94, 10471.25,
        10434.1, 9947.2, 10320.69, 10238.57, 9990.22, 9814.23, 9466.18, 9614.78,
        9798.6, 10152.52, 10179.9, 10363.71, 10602.27, 10913.18, 10805.63,
        10960.11, 10369.57, 10664.84, 10799.77, 11067.66, 11110.68, 11491.98,
        11505.67, 11855.69, 11886.98, 11480.25, 11654.28, 11906.53, 11744.23,
        11677.75, 11151.74, 10709.82, 10658.97, 10655.07, 10874.07, 11026.59,
        11095.03, 11104.81, 11351.2, 11155.65, 10971.84, 11196.71, 11298.4,
        11175.21, 11568.25, 11576.07, 11791.17, 11581.93, 11710.99, 11687.53,
        11824.4, 11754.01, 11644.5, 11396.17, 11601.48, 11364.88, 11411.81,
        11536.96, 11769.65, 11836.14, 11669.93, 11274.93, 11188.89, 11116.54,
        10924.91, 10789.2, 11048.7, 11616.34, 11678.34, 11657.41, 12196.13,
        12252.44, 12502.74, 12412.01, 12033.25, 12330.86, 12099.92, 12151.93,
        12055.93, 12105.99, 11972.63, 11908.1, 12173.65, 12279.82, 12795.66,
        12938.02, 12491.79, 12000.2, 12590.93, 12889.91, 12762.61, 12814.04,
        12850.61, 12540.87, 14381.3, 14351.2, 13978.5, 14342.98, 14637.27,
        14538.13, 13337.9, 13215.69, 13877.4, 13844.54, 14211.19, 13975.76,
        13965.59, 14164.65, 14109.89, 13584.67, 13202.78, 13253.81, 12930.78,
        12713.54, 13648.42, 13610.09, 13709.42, 13512.91, 13502.15, 14147.05,
        13558.67, 13775.91, 13708.83, 13836.14, 13800.55, 13957.96, 13974.38,
        13860.39, 14430.0, 13621.82, 13634.92, 13447.79, 13561.99, 13633.56,
        13517.8, 13423.55, 13686.16, 13929.02, 13861.17, 14089.16, 13802.31,
        13487.29, 13370.36, 13541.65, 13603.45, 13479.27, 13306.03, 13442.51,
        13172.47, 13268.29, 12993.36, 13125.34, 13277.47, 13237.98
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68, 585.32, 586.5, 663.57, 786.51, 797.11, 790.05, 786.51, 767.1,
        806.52, 959.47, 958.3, 952.41, 964.17, 1063.0, 1389.5, 1361.84, 1391.26,
        1364.2, 1244.19, 1260.66, 1483.62, 1459.49, 1424.2, 1434.21, 1352.44,
        1423.61, 1430.09, 1418.31, 1403.61, 1545.39, 1397.72, 1414.21, 1467.15,
        1445.97, 1453.03, 1448.32, 1387.14, 1353.02, 1391.26, 1361.84, 1192.43,
        1176.54, 1222.42, 1269.49, 1202.42, 1276.55, 1398.31, 1318.31, 1363.61,
        1387.73, 1482.44, 1543.03, 1461.85, 1418.31, 1401.85, 1341.84, 1306.55,
        1356.56, 1335.38, 1302.43, 1318.31, 1297.73, 1257.73, 1218.89, 1238.31,
        1218.31, 1179.48, 1080.65, 1007.71, 1064.18, 1055.95, 1166.54, 1208.31,
        1228.9, 1131.83, 1154.78, 1246.54, 1266.54, 1294.2, 1318.9, 1321.84,
        1261.25, 1296.55, 1208.9, 1196.55, 1237.14, 1165.36, 1202.42, 1242.42,
        1201.83, 1258.89, 1245.37, 1208.9, 1203.01, 1232.43, 1230.66, 1211.25,
        1205.36, 1173.01, 1197.72, 1225.95, 1364.79, 1393.03, 1434.79, 1453.62,
        1418.9, 1495.39, 1458.32, 1455.38, 1345.96, 1307.72, 1249.49, 1285.96,
        1263.6, 1256.55, 1276.55, 1247.72, 1308.9, 1368.32, 1355.97, 1361.25,
        1292.43, 1309.49, 1304.19, 1274.2, 1344.2, 1288.9, 1303.6, 1214.78,
        1212.43, 1237.73, 1186.54, 1218.31, 1229.49, 1232.43, 1241.83, 1214.78,
        1234.19, 1203.01, 1197.72, 1190.66, 1156.54, 1153.01, 1178.9, 1131.83,
        1131.24, 1140.06, 1198.31, 1232.43, 1210.66, 1249.49, 1221.83, 1209.48,
        1254.19, 1243.6, 1299.48, 1175.96, 1199.48, 1093.01, 1113.6, 1026.53,
        925.93, 912.41, 894.17, 877.7, 847.69, 868.87, 902.99, 925.35, 904.76,
        939.47, 971.82, 1015.36, 977.71, 1002.41, 973.59, 1001.23, 957.71,
        980.06, 941.24, 1053.0, 1125.95, 1084.77, 1147.71, 1121.83, 1144.77,
        1107.71, 1194.19, 1264.78, 1288.9, 1258.89, 1251.25, 1294.79, 1293.02,
        1516.56, 1548.92, 1608.33, 1623.62, 1566.56, 1500.09, 1552.45, 1500.67,
        1514.8, 1481.26, 1451.85, 1377.15, 1362.43, 1490.09, 1478.32, 1464.79,
        1485.97, 1417.73, 1398.9, 1392.44, 1365.96, 1336.55, 1341.84, 1333.02,
        1367.73, 1355.38, 1417.73, 1432.44, 1447.73, 1445.38, 1470.09, 1428.91,
        1475.39, 1402.43, 1343.61, 1294.2, 1258.89, 1281.84, 1282.43, 1250.07,
        1196.55, 1266.54, 1231.84, 1197.72, 1195.37, 1223.6, 1207.13, 1285.37,
        1225.95, 1164.18, 1133.01, 1147.13, 1147.71, 1137.72, 1189.48, 1152.42,
        1172.42, 1153.01, 1146.54, 1078.89, 1033.59, 1059.48, 1083.59, 1114.19,
        1126.54, 1121.83, 1105.36, 1165.36, 1180.07, 1193.6, 1150.07, 1145.36,
        1141.83, 1147.71, 1139.48, 1114.19, 1121.24, 1167.13, 1193.01, 1260.07,
        1434.21, 1373.03, 1385.96, 1364.79, 1338.9, 1383.02, 1370.67, 1367.14,
        1299.48, 1303.02, 1291.84, 1255.37, 1281.84, 1295.96, 1286.55, 1420.08,
        1410.67, 1390.08, 1459.49, 1607.74, 1748.93, 1721.28, 1906.59, 1783.64,
        1892.47, 1933.05, 1857.75, 1860.7, 1938.94, 2056.0, 2231.89, 2488.97,
        2354.84, 2337.79, 2494.85, 2407.79, 2428.96, 2533.09, 2544.27, 2670.74,
        3330.79, 3423.73, 3357.85, 3073.12, 3363.14, 3830.23, 3416.68, 3454.33,
        3431.97, 3571.98, 3320.19, 3048.41, 3411.97, 3343.72, 3345.49, 3450.21,
        3401.96, 3220.2, 3401.96, 3480.2, 3486.09, 3536.1, 3697.86, 3749.64,
        3584.33, 3555.51, 3690.22, 3994.94, 4325.55, 4346.73, 4363.79, 4102.01,
        3984.94, 4194.36, 4454.38, 4393.79, 4352.02, 4247.31, 4384.97, 4284.37,
        4802.05, 5563.27, 5709.75, 6400.97, 6586.28, 7043.95, 6568.63, 6306.85,
        6103.3, 6175.66, 5989.18, 5913.88, 6358.61, 6352.14, 5812.7, 6163.89,
        5836.23, 5494.45, 5252.66, 5765.05, 5855.06, 6075.06, 5900.35, 5310.31,
        5657.4, 5686.81, 5542.09, 5773.29, 5953.3, 5568.57, 5496.8, 5477.98,
        5382.08, 4939.71, 4863.82, 5147.36, 5133.83, 5429.73, 5511.5, 5579.16,
        5936.23, 6086.24, 5747.4, 5713.28, 5902.11, 5762.1, 5633.87, 5573.86,
        5733.28, 5939.17, 6037.41, 6346.26, 6435.08, 6326.26, 6563.92, 6590.97,
        6889.82, 6589.81, 6453.91, 6425.68, 6635.69, 6592.15, 6403.32, 6154.48,
        6057.41, 5872.7, 6125.07, 6089.19, 6451.55, 6380.38, 6813.93, 7273.96,
        7686.34, 7547.5, 7703.39, 7693.99, 7171.01, 7687.51, 8514.62, 8476.97,
        8526.39, 8343.44, 8698.16, 8727.58, 9615.87, 10172.37, 10832.4,
        10648.28, 11833.05, 11656.57, 9956.48, 11172.42, 10489.45, 10018.82,
        10280.02, 10881.23, 11234.19, 11309.49, 10998.89, 11173.01, 11308.9,
        11540.69, 11801.88, 11344.21, 10830.06, 10950.07, 10723.58, 10259.43,
        10506.51, 10504.74, 10655.92, 10235.32, 10233.55, 10162.36, 8912.88,
        8295.19, 8859.94, 8232.26, 8094.01, 7916.94, 8405.79, 8451.09, 8775.22,
        8762.29, 9296.43, 9134.66, 8642.87, 8546.39, 8189.31, 8275.78, 8181.07,
        8096.95, 7493.39, 7846.35, 8161.66, 8434.02, 8588.75, 8539.33, 9094.07,
        8825.23, 8325.79, 8997.59, 8578.15, 8576.98, 8425.79, 8642.87, 8415.79,
        9169.95, 9345.25, 9921.17, 10119.42, 10084.13, 10918.29, 10500.62,
        10441.79, 10367.09, 9838.82, 9878.83, 9633.51, 10068.24, 9943.53,
        9979.41, 9835.88, 9774.11, 9807.05, 9605.86, 9732.93, 10048.24,
        10187.66, 10032.95, 9594.1, 9324.09, 9525.27, 9375.26, 8793.46, 9153.48,
        8716.4, 8431.09, 8557.56, 8847.59, 8460.5, 8096.95, 7924.59, 7878.12,
        7931.65, 7898.7, 7601.62, 7659.87, 8053.42, 8082.83, 8836.4, 8237.54,
        8239.31, 8649.93, 8635.21, 9101.72, 8315.78, 7792.23, 7896.94, 8463.44,
        7826.93, 8253.43, 8314.61, 8328.14, 8045.77, 8164.01, 8365.2, 8658.16,
        9340.56, 9407.61, 9177.01, 9410.56, 9929.41, 10199.42, 10821.23,
        10501.8, 10171.78, 10434.15, 10135.31, 10779.46, 10923.59, 10790.64,
        10783.58, 10549.45, 10196.49, 9851.76, 8988.76, 8402.85, 8279.31,
        7795.17, 8537.56, 8579.33, 9500.57, 9162.9, 9098.19, 8364.61, 8478.74,
        8653.45, 8491.68, 8452.86, 8212.26, 8325.79, 8505.8, 8446.97, 8426.38,
        8406.38, 8424.03, 8932.29, 9357.62, 9448.79, 9261.73, 8675.81, 8444.03,
        8225.19, 8100.48, 7964.59, 7595.16, 7929.88, 7617.51, 7886.94, 7622.21,
        7336.91, 7459.86, 7612.22, 7909.29, 7999.29, 7799.28, 8021.65, 8198.72,
        8068.71, 7758.11, 7689.87, 7748.11, 7732.22, 8378.14, 8655.22, 8738.75,
        8558.15, 8494.62, 9021.12, 8775.22, 9197.02, 9298.2, 9219.37, 9354.67,
        9082.89, 8511.1, 8327.55, 8055.78, 8408.15, 8410.5, 8550.51, 8498.15,
        8426.38, 8188.72, 8151.07, 8579.92, 8651.11, 8695.22, 9293.49, 8999.95,
        9108.18, 8878.76, 9076.42, 9348.2, 9805.87, 9871.17, 9940.0, 10055.89,
        10514.74, 9635.28, 10081.18, 10457.09, 10508.86, 10564.16, 10315.32,
        9971.77, 9756.46, 9759.41, 9579.39, 9295.85, 9847.05, 11120.66,
        11485.97, 11701.29, 11768.34, 12384.85, 12957.24, 12650.75, 12533.1,
        12475.45, 12838.41, 12600.74, 13809.05, 14084.95, 13850.82, 13909.06,
        14996.18, 14991.48, 14997.95, 14910.89, 13927.29, 13525.51, 14157.9,
        13998.48, 14343.2, 14024.95, 14017.3, 13203.14, 13930.24, 13472.56,
        13826.7, 13967.3, 14040.25, 13922.59, 12816.64, 12617.21, 13428.44,
        13424.92, 13157.25, 12824.88, 12901.35, 12817.23, 13163.13, 12144.25,
        11581.28, 11333.61, 10658.87, 10672.4, 11325.38, 11587.74, 11574.8,
        11121.25, 10873.58, 11481.86, 11148.31, 11245.97, 11243.61, 11443.62,
        12153.66, 12769.0, 12777.22, 12601.92, 12856.05, 11983.65, 11638.34,
        10937.12, 11064.78, 11077.71, 11000.06, 10771.82, 11100.07, 12029.54,
        12340.73, 12896.64, 14967.36, 14879.11, 14712.64, 14670.28, 15299.73,
        14802.05, 14972.66, 15237.37, 14539.68, 13818.47, 13359.03, 13629.63,
        14116.72, 13545.51
      ],
      XRP: [
        10000.0, 10213.33, 10315.77, 10045.8, 10002.42, 9181.63, 9445.58,
        9180.43, 9082.81, 9141.86, 8848.98, 9257.56, 9610.7, 9258.77, 9369.65,
        9419.07, 9390.15, 9152.71, 9121.37, 8959.87, 8842.95, 7636.49, 7064.0,
        7344.83, 7331.57, 7500.3, 7425.58, 7259.25, 7318.31, 7444.86, 7246.0,
        7337.59, 7572.62, 7262.87, 7295.41, 7720.86, 8039.05, 8096.9, 9948.18,
        10386.89, 10800.29, 10178.38, 9071.96, 9973.49, 9787.88, 9675.79,
        9985.53, 10121.73, 9363.63, 9462.46, 9852.96, 9533.57, 8754.97, 8557.31,
        8498.25, 8184.89, 9080.39, 9046.65, 8692.3, 9384.12, 9484.16, 9269.62,
        9053.88, 8595.88, 9081.6, 8921.3, 8720.02, 8665.79, 9174.4, 8934.56,
        9693.87, 9533.57, 9157.53, 9310.6, 9223.82, 9463.66, 9572.14, 9597.44,
        9824.04, 9752.92, 10078.35, 10116.91, 10063.88, 10169.94, 9930.1,
        10021.69, 10277.21, 10646.02, 10289.26, 10354.35, 9874.65, 9987.95,
        10009.64, 10194.05, 9983.13, 9910.81, 9249.12, 9492.59, 9117.76,
        9162.35, 9116.55, 8453.66, 8525.97, 8726.04, 8722.43, 9399.79, 9387.73,
        9243.11, 9234.67, 9304.57, 9086.42, 8763.41, 8673.01, 8580.21, 8452.46,
        8398.22, 7785.95, 7800.42, 7743.76, 7366.52, 7294.2, 7279.74, 7405.09,
        7278.53, 7781.13, 7118.23, 7280.95, 6929.01, 6880.8, 6074.49, 6088.95,
        4945.16, 4559.48, 5183.8, 5146.44, 5347.72, 5129.57, 5260.94, 4959.63,
        5031.94, 4963.24, 5011.45, 5081.35, 4911.41, 4934.31, 4919.86, 4770.4,
        4640.23, 4637.83, 4678.8, 5021.09, 5123.54, 4788.48, 4881.28, 4710.13,
        4727.01, 4780.04, 4840.3, 4901.78, 4849.94, 4828.25, 4649.87, 4400.39,
        4196.69, 3700.14, 3803.78, 4111.13, 3751.96, 3871.28, 3737.49, 3933.95,
        3888.16, 3999.04, 3923.11, 4023.14, 4424.49, 4410.03, 4342.54, 4270.22,
        4078.58, 3988.19, 3897.79, 3773.65, 3811.02, 3847.18, 3952.03, 3942.39,
        3999.04, 4120.76, 4121.97, 4155.72, 3941.19, 3780.89, 3756.78, 3862.84,
        3970.11, 4059.3, 4244.91, 4208.75, 4337.71, 4508.86, 4353.38, 4383.52,
        4334.1, 4329.28, 4330.48, 4146.08, 4044.83, 4317.22, 4513.68, 4476.32,
        4688.44, 4607.69, 4553.46, 4522.12, 4458.24, 4459.45, 4530.56, 4507.66,
        4508.86, 4552.25, 4431.72, 4582.38, 4569.12, 4558.27, 4547.43, 4536.58,
        4512.48, 4524.53, 4542.61, 4551.04, 4046.04, 4068.94, 4159.34, 4108.71,
        4171.39, 4166.57, 4197.9, 4096.66, 4031.57, 3984.57, 3986.98, 3964.09,
        3973.72, 4003.86, 3986.98, 3966.49, 3980.96, 4001.45, 3892.97, 4071.35,
        4120.76, 4261.78, 4332.89, 4267.81, 4297.94, 4012.3, 4124.39, 3933.95,
        4275.04, 4523.32, 4401.6, 4645.05, 5018.68, 4723.4, 5927.45, 6354.1,
        5869.59, 5922.62, 5615.29, 5406.78, 5417.62, 5769.55, 5758.71, 5700.86,
        5453.78, 5553.82, 5787.63, 5961.19, 5929.85, 6251.65, 6191.4, 6432.45,
        6013.02, 5904.54, 5865.98, 5861.16, 5888.88, 5805.71, 5767.15, 5767.15,
        5632.16, 5483.91, 5418.83, 5526.1, 5611.67, 5650.24, 5499.58, 5542.97,
        5644.21, 5571.9, 5711.7, 5645.41, 5530.92, 5573.1, 5611.67, 5447.75,
        5480.29, 6025.07, 5999.76, 5765.94, 5569.48, 4822.22, 3993.01, 4781.25,
        4607.69, 4414.85, 4095.46, 4489.58, 4686.03, 4545.01, 4625.77, 4610.1,
        4631.8, 4389.54, 4370.26, 4557.07, 4579.97, 4843.93, 4940.34, 4757.14,
        4865.62, 4705.32, 4802.94, 4947.57, 4784.87, 4753.53, 4670.36, 4688.44,
        4694.47, 4684.83, 4614.92, 4752.32, 4692.06, 4655.9, 4583.59, 4661.92,
        4749.91, 4649.87, 4547.43, 4244.91, 4243.7, 4220.8, 4078.58, 4252.14,
        4168.98, 4201.52, 4264.19, 4237.67, 4167.77, 4414.85, 4467.88, 4302.76,
        4114.74, 4146.08, 4106.31, 4080.99, 4201.52, 4148.49, 4179.82, 4089.43,
        4170.18, 4144.87, 4124.39, 4217.19, 4232.85, 4481.14, 4526.93, 4624.57,
        4757.14, 4647.47, 4666.75, 4687.24, 4601.67, 4740.27, 4941.55, 4861.99,
        4825.85, 5141.62, 4922.26, 4986.14, 4936.73, 4945.16, 4902.97, 4976.5,
        4737.86, 4909.0, 4994.58, 4951.19, 4965.65, 4993.37, 4807.77, 4778.84,
        4858.38, 4794.51, 4538.99, 4589.61, 4620.94, 4528.14, 4471.5, 4571.54,
        4821.02, 4667.95, 4764.37, 4757.14, 4667.95, 4786.06, 4717.37, 4758.35,
        4687.24, 4541.4, 4542.61, 4540.2, 4576.35, 4549.83, 4619.75, 4560.69,
        4523.32, 4479.93, 4438.95, 4473.9, 4595.64, 4665.54, 4507.66, 4466.68,
        4358.2, 4414.85, 4505.24, 4505.24, 4328.07, 4397.97, 4565.51, 4510.06,
        4719.78, 4536.58, 5565.87, 5072.92, 5368.2, 5092.2, 5352.54, 5427.26,
        5749.07, 6287.81, 6569.84, 6440.88, 6495.12, 6163.67, 6270.94, 6010.6,
        6073.28, 6111.85, 6054.0, 6181.75, 6093.77, 6103.42, 6214.3, 6229.96,
        6075.69, 6174.53, 6340.84, 6280.59, 6287.81, 6180.55, 6375.8, 5981.68,
        5699.65, 5374.23, 5681.57, 5577.92, 5559.84, 5649.03, 5527.3, 5600.82,
        5752.68, 5759.91, 5704.47, 5593.59, 5603.24, 5602.03, 5545.37, 5634.57,
        5510.42, 5473.07, 5131.98, 5141.62, 5191.04, 5058.45, 5185.01, 5115.1,
        5134.38, 5165.72, 5256.12, 5383.88, 5569.48, 5645.41, 5664.7, 5526.1,
        5564.66, 5611.67, 5470.65, 5469.44, 5632.16, 5687.6, 5828.61, 5945.52,
        6289.02, 6255.28, 6134.75, 6322.76, 6261.3, 6524.04, 6091.36, 6399.9,
        6243.22, 6339.65, 6479.45, 6140.77, 6235.99, 6320.36, 6256.48, 5768.36,
        5781.61, 5705.68, 5797.28, 5870.8, 5961.19, 5929.85, 6052.79, 5957.57,
        5979.27, 5865.98, 5912.98, 5782.81, 5810.53, 5604.43, 5715.32, 5735.81,
        5696.04, 5845.48, 5890.08, 5891.29, 5761.12, 5639.38, 5638.19, 5665.9,
        5650.24, 5750.28, 5740.63, 5677.96, 9764.98, 8658.55, 8605.52, 9093.65,
        8888.76, 9269.62, 9850.55, 9645.65, 9285.28, 9021.33, 8859.83, 8481.38,
        8570.57, 8665.79, 8605.52, 8580.21, 8588.65, 8454.86, 8422.32, 8482.59,
        8277.69, 8036.64, 7676.27, 7595.51, 7536.46, 7497.89, 7765.45, 7723.28,
        7626.85, 7625.65, 7571.41, 7549.72, 7624.44, 7337.59, 7079.67, 6150.42,
        6105.82, 6251.65, 6536.1, 6316.74, 6239.61, 6364.95, 6240.81, 6344.47,
        6320.36, 6299.87, 6303.49, 6533.69, 6362.55, 6169.7, 6000.96, 6011.81,
        6076.89, 6123.9, 6093.77, 6057.61, 6088.95, 6088.95, 6072.08, 6009.41,
        5700.86, 5816.56, 5835.84, 5925.03, 6060.02, 6029.89, 5937.09, 6090.16,
        6191.4, 6278.17, 6126.31, 6197.42, 6111.85, 6109.44, 6084.13, 6031.1,
        6033.51, 6126.31, 6281.79, 6222.73, 6330.0, 6168.5, 6363.74, 6428.83,
        6316.74, 6344.47, 6289.02, 6232.38, 6058.82, 6015.43, 5878.03, 5840.66,
        5862.37, 5861.16, 5875.62, 6013.02, 5920.22, 5893.69, 6129.93, 6269.73,
        6295.05, 6292.64, 6631.31, 6774.74, 6684.34, 6675.9, 6575.87, 6580.7,
        6715.68, 6970.0, 7209.84, 7349.65, 7277.33, 7385.8, 7429.2, 7857.06,
        8697.12, 8222.25, 8284.92, 7996.87, 7970.36, 8020.97, 8013.74, 7882.37,
        7587.08, 7814.87, 7413.52, 7421.96, 7355.67, 7505.13, 7385.8, 6996.51,
        7394.24, 7477.4, 7478.61, 7479.81, 7456.92, 7244.79, 7356.88, 7332.77,
        7297.82, 7373.75, 7446.07, 7538.87, 7507.53, 7471.37, 7659.39, 7730.5,
        8055.92, 8088.47, 7986.02, 7473.79, 7446.07, 7587.08, 7626.85, 7459.32,
        7467.76, 7389.42, 7374.96, 7311.08, 7455.71, 7517.18, 7524.4, 7466.55,
        7355.67, 7759.43, 7482.23, 7624.44, 7635.3, 7465.35, 7487.05, 7368.93,
        7555.74, 7560.56, 7027.85, 7083.28, 6918.16, 6847.05, 6636.13, 6990.48,
        6891.65, 7324.33, 7260.45, 6838.62, 6945.89, 7055.56, 6970.0, 6970.0,
        6874.78, 6661.45, 6589.13, 6630.11, 6608.41, 6377.0, 6210.68, 6223.94,
        6204.65, 6415.58, 6395.08, 6309.51, 6463.78, 6145.59, 6051.58, 6093.77,
        6158.85, 6269.73, 6072.08, 6120.29, 6119.08, 6173.32, 6197.42, 6338.44,
        6316.74, 6326.39, 6402.32, 6339.65, 6498.74, 6759.07, 6809.69, 6632.52,
        6750.64, 6821.75, 6802.46, 6601.18, 6551.77, 6437.27, 6577.07, 6560.2,
        6627.7, 7071.23, 6904.91, 7041.1, 7239.97, 7658.19, 7577.44, 7853.44,
        7098.96, 7356.88, 7611.19, 7449.68, 7493.07, 7233.94, 8747.75, 8282.52,
        8268.05, 8065.56, 7631.67, 7305.06, 7472.58, 7629.27, 7066.41, 7385.8,
        7694.34, 7333.98, 7501.51, 7632.88, 7753.4, 7630.47, 7405.09, 7534.05,
        7575.03, 7472.58, 7575.03, 7374.96, 7094.13, 6930.21, 7108.59, 7072.44,
        7161.63, 7142.34, 7459.32, 7502.71, 7438.84, 7330.36, 6568.64, 5732.2,
        6082.92, 6016.63, 5988.91, 5991.33, 6061.23, 6094.97, 6343.26, 6337.23,
        6733.76, 6589.13, 6337.23, 6339.65, 6316.74, 6234.78, 6186.58, 6214.3,
        6022.66, 6203.45, 6261.3, 6427.63, 6377.0, 6375.8, 6532.48, 6369.77,
        6227.56, 6279.38, 6046.76, 6097.39, 6019.04, 6078.1, 6033.51, 6242.02,
        6210.68, 6316.74, 6272.15, 6145.59, 6446.91, 6486.69, 6351.69, 6348.08,
        6454.14, 6526.46, 6383.03, 6454.14, 6383.03, 6323.97, 6263.71, 6256.48,
        6267.33, 6193.8, 6280.59, 6332.41, 6334.82, 6290.23, 6035.92, 5946.73,
        6005.78, 5986.51, 5788.84, 5920.22, 5776.79, 5722.55, 5933.48, 5897.32,
        6109.44, 5920.22, 5944.32, 5896.11, 5906.96, 5873.21, 5784.02, 5692.42,
        5733.39, 5680.36, 5734.6, 5685.19, 5691.22, 5747.86, 5755.1, 5844.29,
        5627.33, 5356.16, 5122.33, 5412.8, 5069.3, 5199.47, 5248.89, 5282.63,
        5395.93, 5717.73, 6310.71, 6364.95, 6455.35, 6950.71, 7632.88, 6859.1,
        6877.18, 7186.94, 7202.6, 7320.72, 7217.07, 7348.44, 7189.34, 7268.9,
        7241.17, 7217.07, 7261.66, 7596.72, 7642.52, 7212.24, 6760.27, 6691.58,
        6366.16, 5978.07, 6117.87, 7395.44, 7371.34, 7007.35, 7074.85, 6654.21,
        6819.33, 6942.27, 6851.88, 6756.66, 6800.05, 6816.92, 6886.83, 7195.37,
        7168.86, 7238.76, 7203.81, 7362.91, 7348.44, 7267.69, 7096.54, 6827.77,
        6873.57, 6755.46, 6825.36, 6838.62, 6573.46, 6843.44, 6785.59, 6747.01,
        6566.23, 6268.54, 6321.57, 6348.08, 6499.94, 6531.28, 6454.14, 6789.2,
        6898.88, 7180.91, 6945.89, 7010.97, 7056.77, 7007.35, 7106.18, 7078.46,
        7214.66, 7104.98, 7056.77, 7139.93, 7050.74, 7120.65, 7088.1, 7421.96,
        7718.45, 7461.73, 7172.47, 6569.84, 6326.39, 6439.68, 6414.37, 6420.4,
        6407.14, 6383.03, 6340.84, 6374.6, 6501.14, 6503.56, 6402.32, 6620.47,
        6511.99, 6637.34, 6585.52, 6571.05, 6551.77, 6608.41, 6584.31, 6448.11,
        6332.41, 6409.55, 6169.7, 6174.53, 6226.35, 6264.91, 6357.72, 6316.74,
        6156.45, 6163.67, 6145.59, 6067.26, 6066.05, 6193.8, 6577.07, 6667.47,
        6669.88, 6689.16, 7109.8, 7448.47, 8622.4, 8330.72, 9560.09, 10831.63,
        13331.32, 12857.66, 13557.92, 13144.51, 13392.8, 14520.91, 17669.04,
        17873.93, 17391.83, 17118.23, 16751.85, 17794.39, 18178.86, 21138.97,
        23046.89, 26155.24, 33181.88, 31406.54, 28574.19, 27542.49, 29496.21,
        30655.67, 31264.31, 26709.66, 28130.65, 29323.86, 28330.72, 29112.94,
        28922.51, 28941.79, 30190.44, 30779.81, 28304.21, 27526.82, 27554.54,
        26941.06, 26640.96, 27214.66, 28036.64, 27666.63, 26133.55, 25817.77,
        26481.86, 25195.86, 24887.31, 25054.85, 28315.06, 28993.62, 29645.66,
        29280.47, 28963.49, 29184.05, 27677.47, 28788.73, 27611.19, 28235.52,
        30712.31, 30087.99, 30501.39, 32159.82, 37147.16, 38869.48, 39398.58,
        38810.42, 36771.13, 37899.25, 38213.82, 38153.56, 37603.96, 37301.44,
        37647.34, 37137.52, 36579.49, 37060.39, 37114.63, 37782.34, 36602.39
      ],
      USDT: [
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000
      ],
      HODLIE: [
        10000, 10001.07, 9974.27, 9776.82, 9923.18, 9285.16, 9291.71, 9288.75,
        9287.43, 9325.02, 9106.68, 9127.95, 9420.67, 9139.51, 9150.35, 9208.18,
        9206.41, 8825.17, 8858.32, 8857.9, 8855.92, 8849.67, 8846.57, 8848.46,
        8848.04, 8869.35, 8810.53, 8609.35, 8682.15, 8851.37, 8777.21, 9084.48,
        9532.59, 9077.91, 9047.28, 9616.72, 9796.63, 9712.18, 10230.58,
        10333.21, 10512.5, 10246.28, 10085.22, 10086.22, 10085.84, 10086.28,
        10332.87, 10364.43, 9683.83, 9653.14, 9653.26, 9651.56, 9601.61,
        9602.49, 9342.44, 9159.05, 9478.59, 9250.83, 8813.47, 9068.74, 9254.67,
        9238.82, 8951.94, 8883.45, 8884.42, 8883.96, 8774.94, 8749.75, 8703.11,
        8238.79, 8125.12, 8030.25, 7893.01, 7949.14, 7768.86, 7997.64, 8144.54,
        8425.43, 8435.57, 8288.75, 8367.6, 8280.49, 8316.54, 8527.16, 8319.34,
        8483.28, 8768.23, 8919.46, 8966.97, 8975.54, 9135.89, 9634.93, 9539.04,
        9679.71, 9673.83, 9476.76, 9302.88, 9304.18, 9302.69, 9302.94, 9205.75,
        9147.25, 9148.9, 9150.17, 9066.61, 9354.38, 9349.07, 9287.02, 9139.84,
        9261.17, 9174.33, 8942.84, 8870.88, 8853.52, 8853.12, 8853.68, 8427.28,
        8399.99, 8395.14, 8302.31, 8191.84, 8121.43, 8121.39, 7938.01, 8285.11,
        7821.99, 7842.72, 7840.93, 7840.35, 7835.39, 7836.3, 7830.21, 7829.35,
        7833.08, 7833.09, 8243.14, 7969.86, 8154.32, 7679.37, 7622.19, 7532.49,
        7578.74, 7671.75, 7700.27, 7771.9, 7783.13, 7604.17, 7525.25, 7525.88,
        7595.07, 8049.12, 7901.85, 7401.77, 7526.8, 7526.44, 7526.87, 7484.53,
        7340.59, 7040.92, 6858.23, 6850.1, 6684.42, 6627.07, 6625.43, 6620.59,
        6620.35, 6621.93, 6120.96, 6202.95, 6284.46, 6577.42, 6602.08, 6799.66,
        6547.01, 6799.54, 6969.55, 6972.71, 6850.58, 6792.43, 6374.16, 6347.98,
        6346.84, 6347.37, 6347.58, 6418.54, 6764.92, 6810.94, 7010.57, 7210.28,
        7217.3, 7171.05, 6951.55, 6951.03, 6950.49, 6951.08, 6952.63, 7226.05,
        7775.57, 7786.16, 8548.69, 8788.85, 8609.21, 8555.03, 8462.45, 8406.27,
        8665.4, 8339.84, 8339.46, 8343.19, 8821.52, 8807.71, 8618.61, 8452.6,
        8449.24, 8428.51, 8388.65, 8528.92, 8605.34, 8613.12, 8648.8, 8972.09,
        8736.91, 9271.03, 9370.54, 9623.77, 9826.49, 9628.33, 9432.35, 9481.62,
        9480.3, 9454.16, 9368.83, 9369.03, 9371.84, 9336.03, 9315.57, 9289.03,
        9452.72, 8979.37, 8969.14, 8969.55, 8970.34, 8931.92, 9047.61, 9136.96,
        9106.71, 8940.31, 8945.29, 8838.9, 8649.3, 8334.82, 8337.82, 8731.48,
        8960.87, 8908.99, 9152.43, 8611.1, 8552.42, 8551.0, 8551.51, 8757.29,
        8660.87, 8872.86, 9180.48, 8938.96, 9896.11, 10206.64, 9858.0, 9894.14,
        9799.38, 9693.75, 9632.16, 9622.16, 9581.82, 9538.71, 9360.34, 9305.82,
        9588.29, 9543.08, 9557.86, 9780.04, 9738.69, 9903.59, 9618.44, 9573.51,
        9573.51, 9573.85, 9460.19, 9354.2, 9495.34, 9544.27, 9419.48, 9256.01,
        9239.77, 9239.88, 9226.57, 9531.63, 9415.57, 10092.47, 10752.31,
        10448.31, 10696.32, 11007.79, 10868.88, 10749.73, 10766.37, 10697.84,
        10810.82, 11094.23, 11288.04, 10628.57, 10208.76, 10253.64, 10246.28,
        10253.35, 10250.29, 10241.42, 10012.84, 9956.03, 10059.28, 9934.28,
        9981.74, 9977.74, 10017.74, 9781.34, 9736.76, 9738.56, 10070.18,
        10279.79, 10080.09, 10050.42, 10167.17, 9909.95, 9910.65, 10480.8,
        10329.89, 10421.19, 10241.74, 10224.53, 10274.49, 10372.76, 10182.65,
        10151.64, 10031.79, 10048.32, 10001.44, 9995.84, 10287.03, 10229.41,
        10110.4, 9462.3, 9361.9, 9363.81, 9361.59, 9505.95, 9465.47, 9507.23,
        9585.78, 9553.79, 9484.0, 9463.53, 9562.25, 9421.38, 9254.51, 9214.81,
        9200.35, 9186.51, 9849.03, 10862.45, 10935.09, 10879.28, 10844.57,
        10847.11, 10902.56, 12055.03, 12022.0, 11971.1, 12064.07, 12554.32,
        13440.27, 13285.95, 13417.09, 13254.17, 12886.31, 12855.9, 13107.47,
        12927.81, 12740.19, 12774.68, 12564.51, 12643.88, 12617.93, 12620.53,
        12479.47, 12726.92, 12070.66, 12151.48, 12090.89, 11997.34, 12070.56,
        12150.54, 12018.11, 12001.84, 12002.84, 11866.58, 11161.4, 11197.53,
        11198.37, 11199.1, 10861.65, 11128.99, 11730.98, 11614.62, 11772.94,
        11759.51, 11706.52, 12044.14, 11667.33, 11478.24, 11408.53, 11255.34,
        11269.69, 11270.75, 11270.13, 11135.2, 11397.89, 11320.06, 10791.19,
        10756.97, 10757.07, 10769.32, 10633.55, 10734.12, 10580.22, 10429.48,
        10428.91, 10430.82, 10686.34, 10861.04, 10732.6, 10970.52, 11931.98,
        11706.33, 12158.58, 11905.04, 11893.22, 11373.0, 11659.24, 11409.44,
        11409.14, 11539.7, 11842.8, 12322.9, 12571.21, 12464.2, 12512.3,
        12435.48, 12246.68, 12082.16, 12073.63, 12314.44, 12088.25, 12059.6,
        12023.7, 12035.41, 12480.56, 12726.03, 12913.35, 13117.6, 13249.37,
        13198.39, 13370.48, 13068.66, 12938.32, 12478.96, 12477.34, 12477.62,
        12479.67, 12455.85, 12467.52, 12504.19, 12510.48, 12925.3, 12908.84,
        12804.94, 12831.04, 12437.94, 12358.14, 12402.87, 12191.49, 12748.18,
        12230.39, 12225.0, 12223.15, 12222.72, 12223.33, 12221.56, 12248.46,
        12235.23, 12252.13, 12336.12, 12213.67, 12260.32, 12444.83, 12529.42,
        12546.83, 12412.79, 12472.19, 12684.52, 12357.57, 12349.43, 12363.94,
        12413.78, 12584.15, 12378.62, 12621.95, 12592.33, 12492.69, 12668.5,
        12564.21, 12744.39, 12273.07, 12305.78, 12172.57, 12170.19, 12157.11,
        11716.24, 11715.27, 11714.3, 11331.79, 11007.62, 11003.56, 10946.34,
        11018.58, 10957.77, 11109.62, 11541.7, 12213.26, 12145.25, 12397.17,
        12350.08, 12345.82, 12000.64, 11931.44, 11826.74, 11767.71, 12228.09,
        11978.49, 12371.62, 12324.19, 12346.65, 12054.14, 12187.88, 12615.94,
        12764.57, 12569.04, 12522.03, 12567.09, 12466.74, 13703.27, 13057.59,
        13023.64, 13343.48, 13187.56, 13427.47, 13787.78, 13665.77, 13452.42,
        13404.08, 13381.95, 13045.59, 13045.43, 13045.67, 13014.86, 12947.37,
        13046.14, 12860.89, 12796.11, 12825.64, 12645.03, 12633.94, 12581.99,
        12592.68, 12615.03, 12543.09, 12855.64, 12924.65, 12997.12, 12972.27,
        12962.12, 12774.22, 12912.1, 12551.13, 12560.55, 12557.33, 12555.96,
        12555.97, 12555.89, 12414.98, 12354.96, 12366.15, 12074.96, 12065.77,
        12057.29, 11991.57, 12006.72, 12443.44, 12224.0, 11762.33, 11582.05,
        11587.65, 11587.44, 11537.12, 11509.37, 11287.94, 11215.68, 11076.58,
        11077.43, 11056.04, 11041.09, 10985.87, 10935.24, 11025.92, 10981.79,
        10942.31, 10862.36, 10794.34, 10880.12, 10951.09, 10756.82, 10818.91,
        10796.64, 10754.3, 10706.93, 10681.76, 10559.04, 10855.99, 10951.21,
        10996.03, 11828.84, 11531.65, 11597.37, 11503.76, 11391.62, 11419.74,
        11407.95, 11396.44, 11226.48, 11227.07, 11226.53, 11226.06, 11226.21,
        11226.29, 11208.51, 11881.21, 11819.56, 11725.54, 12061.71, 12762.86,
        13406.8, 13291.59, 14117.07, 13823.42, 13979.77, 13847.85, 13536.34,
        13564.12, 13720.74, 14196.37, 14888.82, 15853.09, 15389.68, 15315.72,
        15894.24, 15693.38, 15965.37, 15591.5, 15644.12, 16119.98, 16979.02,
        17251.68, 17063.04, 16239.83, 15911.04, 17227.2, 16148.38, 16249.78,
        16162.31, 16172.04, 15997.2, 15430.1, 15669.43, 15674.85, 15727.17,
        15824.59, 15561.89, 15101.67, 15102.44, 14965.03, 14972.62, 15089.29,
        15395.97, 15685.91, 15989.8, 16758.18, 16587.42, 16381.55, 17219.87,
        17269.92, 17318.33, 16929.35, 17186.05, 17137.09, 17376.95, 17245.67,
        17153.08, 17020.5, 17020.34, 16816.71, 17411.19, 18975.66, 19256.85,
        20602.56, 20938.66, 21775.6, 20936.54, 21554.58, 21747.05, 20821.85,
        20825.83, 20859.31, 21142.99, 21455.97, 20536.21, 21187.53, 20983.12,
        20704.13, 20653.35, 21590.93, 21292.39, 22079.59, 22590.43, 21715.63,
        22313.01, 21993.65, 22079.14, 22296.26, 22037.4, 21210.07, 21216.64,
        21256.19, 21331.74, 21155.15, 21152.01, 21150.32, 20894.62, 21600.06,
        21784.62, 21527.42, 22322.25, 22657.96, 21972.03, 21944.29, 21888.37,
        21738.37, 21377.63, 21047.52, 21509.15, 21852.6, 21846.62, 22616.61,
        22845.25, 22972.12, 23752.18, 23502.29, 24552.7, 24875.48, 24692.43,
        24493.38, 24501.42, 24976.23, 25221.05, 24786.32, 25150.27, 24876.69,
        25211.31, 26043.32, 26647.95, 27279.21, 29094.7, 29072.13, 28723.64,
        28421.63, 28537.15, 28445.73, 26301.14, 28299.18, 28592.7, 28615.45,
        28720.2, 28537.99, 28867.26, 28110.14, 28106.84, 29029.23, 30140.3,
        29863.56, 31750.33, 31487.71, 29598.73, 29612.5, 30018.78, 29787.03,
        30335.51, 30289.11, 30348.01, 30274.19, 29971.5, 30498.46, 30260.11,
        29856.47, 30249.17, 29562.75, 28395.59, 28397.09, 28285.01, 28271.85,
        28642.34, 28692.24, 30641.34, 29194.43, 29340.51, 29187.89, 29006.81,
        28250.84, 28209.84, 28207.18, 28203.14, 28047.82, 27989.85, 28153.24,
        28781.97, 28764.16, 29769.09, 29470.56, 28350.97, 28468.09, 28257.83,
        28256.83, 28497.07, 27934.57, 26346.74, 26342.39, 26290.82, 26803.15,
        27083.98, 27054.02, 26921.06, 26497.18, 26012.71, 26058.52, 25108.67,
        25219.25, 24973.99, 24979.82, 24530.9, 26046.4, 25702.32, 26610.42,
        26918.06, 26868.58, 28137.33, 29226.83, 28770.68, 29209.46, 28534.45,
        28498.43, 28262.27, 28602.15, 28443.57, 28218.13, 27869.11, 27868.89,
        27870.2, 27634.98, 27736.44, 28649.79, 28853.94, 29104.19, 28664.78,
        28635.97, 28634.09, 28622.11, 27628.84, 27632.6, 27628.3, 27454.77,
        27764.69, 28154.71, 27645.25, 27264.61, 27295.45, 26923.96, 26950.32,
        26727.38, 26725.09, 26721.29, 26611.47, 26673.06, 28104.36, 26995.52,
        26994.49, 27769.06, 27773.47, 28227.15, 26806.46, 26728.84, 26721.89,
        26730.12, 25622.26, 26437.57, 26410.24, 26720.07, 26735.5, 26546.86,
        27726.55, 27830.41, 28748.44, 28852.82, 29258.93, 27955.08, 28201.09,
        28647.89, 29658.4, 29150.42, 28627.22, 28869.58, 28589.69, 29032.24,
        29259.57, 28861.3, 28859.63, 28892.81, 28958.73, 28256.98, 28162.36,
        28158.35, 28152.05, 28143.49, 28149.37, 28304.34, 29219.39, 28736.03,
        28722.1, 28233.96, 28275.32, 28434.28, 28043.33, 27643.49, 27614.62,
        27620.23, 27252.75, 27098.3, 27080.79, 27197.89, 26887.48, 27006.83,
        26888.56, 26979.72, 26666.2, 26334.06, 26335.2, 26334.9, 26334.73,
        26230.34, 26019.72, 26263.93, 25799.64, 25671.87, 25205.07, 25168.48,
        25170.31, 25172.66, 25324.44, 25341.69, 25288.29, 25547.63, 25734.61,
        25538.75, 25152.67, 24594.36, 24670.83, 24437.22, 24821.85, 25018.73,
        25237.09, 25357.03, 25838.13, 26005.39, 25475.47, 26175.82, 26344.84,
        26249.85, 26519.41, 26125.5, 25696.71, 25714.21, 25714.25, 25716.92,
        25679.65, 25818.98, 25471.41, 25786.46, 25607.47, 25593.73, 25765.92,
        25883.01, 25739.93, 26979.86, 26515.21, 26853.82, 26733.16, 26995.4,
        27037.65, 27698.29, 27802.75, 27911.06, 28094.5, 28831.51, 27422.58,
        27485.77, 27802.74, 27825.37, 28219.78, 27957.48, 27301.51, 27117.38,
        27116.46, 27113.96, 27112.3, 27115.4, 27745.24, 28905.19, 29576.57,
        31097.15, 31526.8, 32873.9, 33082.76, 33440.11, 32776.35, 34885.55,
        38293.76, 37741.04, 38614.33, 37258.01, 37281.31, 38534.56, 38682.48,
        38901.74, 38483.37, 38657.48, 37376.85, 39269.47, 38593.81, 39599.01,
        41141.43, 43541.04, 48540.76, 47454.07, 45651.91, 44153.23, 45473.19,
        45186.65, 45051.54, 42142.16, 42094.43, 42104.63, 41822.57, 41770.69,
        41448.13, 41769.71, 42659.94, 42036.56, 40045.08, 38840.98, 38507.67,
        38502.79, 38499.62, 39675.99, 39313.88, 39276.2, 37995.86, 37433.0,
        37361.75, 36373.91, 36735.06, 36733.18, 36512.5, 36987.01, 37342.82,
        37197.02, 37009.09, 37308.41, 35740.85, 35644.21, 35641.75, 35643.25,
        36973.56, 36652.43, 36918.76, 37781.69, 39678.65, 40485.23, 40727.31,
        41666.84, 41662.0, 41407.71, 41367.22, 40102.03, 39570.93, 39693.95,
        39268.52, 38388.47, 37782.74, 37437.16, 37330.95, 37723.62, 37424.3
      ]
    },
    2022: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31',
        '2023-01-01'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14, 3574.62
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32, 3253.85
      ],
      BNB: [
        10000.0, 10275.72, 10387.17, 10023.47, 9974.58, 9305.83, 9317.56,
        8760.27, 8506.07, 8609.7, 8273.37, 9026.21, 9485.73, 9325.38, 9587.41,
        9696.91, 9763.4, 9256.94, 9247.16, 9159.17, 8756.35, 7465.78, 6941.73,
        7260.46, 7195.94, 7471.64, 7291.75, 7573.33, 7508.81, 7622.21, 7356.27,
        7354.32, 7543.99, 7217.44, 7217.44, 7737.58, 8101.3, 8046.54, 8502.15,
        8023.08, 8277.28, 8201.02, 7706.29, 7884.24, 7815.8, 7888.15, 8410.25,
        8371.14, 7919.44, 7774.74, 7800.16, 7524.44, 7104.02, 7252.64, 7254.59,
        6937.82, 7326.94, 7303.49, 7102.07, 7692.61, 7970.28, 8007.43, 7868.6,
        7338.68, 7520.53, 7442.32, 7469.69, 7436.45, 7661.32, 7280.02, 7303.49,
        7332.81, 7082.52, 7227.22, 7276.11, 7487.29, 7653.5, 7735.63, 7802.11,
        7686.74, 7755.18, 7940.95, 7948.76, 8093.47, 8025.03, 8103.25, 8388.73,
        8498.24, 8470.87, 8664.45, 8437.62, 8705.52, 8629.25, 8818.92, 8756.35,
        8779.82, 8275.32, 8537.35, 8246.0, 8289.01, 8214.7, 7747.36, 8066.1,
        8283.15, 8103.25, 8142.35, 8152.13, 8093.47, 8169.73, 8228.4, 8201.02,
        7915.53, 7960.5, 7903.79, 7831.44, 7894.01, 7545.96, 7622.21, 7983.96,
        7690.66, 7538.13, 7598.74, 7600.71, 7487.29, 7862.73, 7356.27, 7430.59,
        7088.39, 6978.88, 6028.55, 6138.06, 5295.27, 5215.1, 5784.12, 5766.52,
        6052.01, 5840.83, 5960.11, 5725.46, 5958.16, 5917.09, 6120.46, 6224.09,
        6261.25, 6415.72, 6437.23, 6063.74, 5934.69, 6007.04, 5981.62, 6296.44,
        6300.35, 5883.84, 6024.64, 5827.14, 5897.54, 5860.39, 5789.99, 5658.97,
        5668.76, 5658.97, 5621.82, 5342.2, 5039.11, 4301.91, 4266.72, 4518.96,
        4098.56, 4227.61, 3887.37, 4211.97, 4219.79, 4342.98, 4227.61, 4470.08,
        4710.6, 4669.53, 4608.91, 4583.5, 4440.75, 4323.43, 4170.91, 4247.16,
        4280.41, 4274.54, 4501.36, 4560.03, 4665.63, 4710.6, 4732.11, 4751.66,
        4579.58, 4417.29, 4344.94, 4429.01, 4636.29, 4693.0, 4904.18, 4892.45,
        5078.22, 5281.58, 5043.02, 5172.08, 5164.25, 5066.48, 5117.32, 4898.32,
        4829.88, 5263.98, 5434.1, 5809.54, 5658.97, 5543.61, 5531.87, 5565.12,
        5864.29, 6079.39, 6184.98, 6198.68, 6341.41, 6337.51, 6378.57, 6400.08,
        6317.95, 6353.15, 6360.97, 6233.86, 6212.36, 6179.11, 5977.71, 5915.14,
        5482.99, 5520.14, 5909.27, 5866.24, 5844.74, 5799.76, 5897.54, 5529.92,
        5408.69, 5461.47, 5561.21, 5531.87, 5482.99, 5434.1, 5426.29, 5426.29,
        5424.32, 5396.95, 5187.72, 5434.1, 5494.72, 5727.42, 5817.36, 5739.14,
        5705.91, 5420.42, 5469.3, 5281.58, 5365.67, 5461.47, 5207.27, 5305.05,
        5222.92, 5136.88, 5371.53, 5404.77, 5400.86, 5334.37, 5371.53, 5332.42,
        5502.54, 5512.32, 5514.27, 5543.61, 5584.67, 5588.59, 5782.17, 5746.97,
        5555.34, 5526.01, 5402.82, 5426.29, 5326.55, 5297.22, 5295.27, 5340.24,
        5262.02, 5248.34, 5334.37, 5379.35, 5342.2, 5314.82, 5260.07, 5275.72,
        5285.49, 5396.95, 5350.02, 5578.8, 5694.17, 5592.49, 5838.87, 5917.09,
        6106.76, 6423.55, 6359.01, 6253.43, 6501.76, 6888.93, 6840.05, 6824.4,
        6507.63, 6175.21, 5263.98, 5979.66, 5647.24, 5533.82, 5406.72, 5391.09,
        5387.17, 5316.77, 5250.29, 5344.15, 5338.29, 5170.12, 4966.76, 5211.19,
        5795.86, 5877.98, 5868.21, 6069.61, 6138.06, 5727.42, 5809.54, 5876.03,
        5725.46, 5707.86, 5684.39, 5696.13, 5635.51, 5647.24, 5555.34, 5664.84,
        5590.54, 5629.64, 5582.72, 5410.64, 5312.87, 5238.57, 5029.33, 4501.36,
        4696.91, 4913.96, 4698.86, 4908.1, 4822.06, 4802.5, 4812.28, 4779.03,
        4749.71, 4743.84, 4814.23, 4759.48, 4804.46, 4794.68, 4816.2
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68, 585.32
      ],
      XRP: [
        10000.0, 10213.33, 10315.77, 10045.8, 10002.42, 9181.63, 9445.58,
        9180.43, 9082.81, 9141.86, 8848.98, 9257.56, 9610.7, 9258.77, 9369.65,
        9419.07, 9390.15, 9152.71, 9121.37, 8959.87, 8842.95, 7636.49, 7064.0,
        7344.83, 7331.57, 7500.3, 7425.58, 7259.25, 7318.31, 7444.86, 7246.0,
        7337.59, 7572.62, 7262.87, 7295.41, 7720.86, 8039.05, 8096.9, 9948.18,
        10386.89, 10800.29, 10178.38, 9071.96, 9973.49, 9787.88, 9675.79,
        9985.53, 10121.73, 9363.63, 9462.46, 9852.96, 9533.57, 8754.97, 8557.31,
        8498.25, 8184.89, 9080.39, 9046.65, 8692.3, 9384.12, 9484.16, 9269.62,
        9053.88, 8595.88, 9081.6, 8921.3, 8720.02, 8665.79, 9174.4, 8934.56,
        9693.87, 9533.57, 9157.53, 9310.6, 9223.82, 9463.66, 9572.14, 9597.44,
        9824.04, 9752.92, 10078.35, 10116.91, 10063.88, 10169.94, 9930.1,
        10021.69, 10277.21, 10646.02, 10289.26, 10354.35, 9874.65, 9987.95,
        10009.64, 10194.05, 9983.13, 9910.81, 9249.12, 9492.59, 9117.76,
        9162.35, 9116.55, 8453.66, 8525.97, 8726.04, 8722.43, 9399.79, 9387.73,
        9243.11, 9234.67, 9304.57, 9086.42, 8763.41, 8673.01, 8580.21, 8452.46,
        8398.22, 7785.95, 7800.42, 7743.76, 7366.52, 7294.2, 7279.74, 7405.09,
        7278.53, 7781.13, 7118.23, 7280.95, 6929.01, 6880.8, 6074.49, 6088.95,
        4945.16, 4559.48, 5183.8, 5146.44, 5347.72, 5129.57, 5260.94, 4959.63,
        5031.94, 4963.24, 5011.45, 5081.35, 4911.41, 4934.31, 4919.86, 4770.4,
        4640.23, 4637.83, 4678.8, 5021.09, 5123.54, 4788.48, 4881.28, 4710.13,
        4727.01, 4780.04, 4840.3, 4901.78, 4849.94, 4828.25, 4649.87, 4400.39,
        4196.69, 3700.14, 3803.78, 4111.13, 3751.96, 3871.28, 3737.49, 3933.95,
        3888.16, 3999.04, 3923.11, 4023.14, 4424.49, 4410.03, 4342.54, 4270.22,
        4078.58, 3988.19, 3897.79, 3773.65, 3811.02, 3847.18, 3952.03, 3942.39,
        3999.04, 4120.76, 4121.97, 4155.72, 3941.19, 3780.89, 3756.78, 3862.84,
        3970.11, 4059.3, 4244.91, 4208.75, 4337.71, 4508.86, 4353.38, 4383.52,
        4334.1, 4329.28, 4330.48, 4146.08, 4044.83, 4317.22, 4513.68, 4476.32,
        4688.44, 4607.69, 4553.46, 4522.12, 4458.24, 4459.45, 4530.56, 4507.66,
        4508.86, 4552.25, 4431.72, 4582.38, 4569.12, 4558.27, 4547.43, 4536.58,
        4512.48, 4524.53, 4542.61, 4551.04, 4046.04, 4068.94, 4159.34, 4108.71,
        4171.39, 4166.57, 4197.9, 4096.66, 4031.57, 3984.57, 3986.98, 3964.09,
        3973.72, 4003.86, 3986.98, 3966.49, 3980.96, 4001.45, 3892.97, 4071.35,
        4120.76, 4261.78, 4332.89, 4267.81, 4297.94, 4012.3, 4124.39, 3933.95,
        4275.04, 4523.32, 4401.6, 4645.05, 5018.68, 4723.4, 5927.45, 6354.1,
        5869.59, 5922.62, 5615.29, 5406.78, 5417.62, 5769.55, 5758.71, 5700.86,
        5453.78, 5553.82, 5787.63, 5961.19, 5929.85, 6251.65, 6191.4, 6432.45,
        6013.02, 5904.54, 5865.98, 5861.16, 5888.88, 5805.71, 5767.15, 5767.15,
        5632.16, 5483.91, 5418.83, 5526.1, 5611.67, 5650.24, 5499.58, 5542.97,
        5644.21, 5571.9, 5711.7, 5645.41, 5530.92, 5573.1, 5611.67, 5447.75,
        5480.29, 6025.07, 5999.76, 5765.94, 5569.48, 4822.22, 3993.01, 4781.25,
        4607.69, 4414.85, 4095.46, 4489.58, 4686.03, 4545.01, 4625.77, 4610.1,
        4631.8, 4389.54, 4370.26, 4557.07, 4579.97, 4843.93, 4940.34, 4757.14,
        4865.62, 4705.32, 4802.94, 4947.57, 4784.87, 4753.53, 4670.36, 4688.44,
        4694.47, 4684.83, 4614.92, 4752.32, 4692.06, 4655.9, 4583.59, 4661.92,
        4749.91, 4649.87, 4547.43, 4244.91, 4243.7, 4220.8, 4078.58, 4252.14,
        4168.98, 4201.52, 4264.19, 4237.67, 4167.77, 4414.85, 4467.88, 4302.76,
        4114.74, 4146.08, 4106.31
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10001.07, 9974.27, 9776.82, 9923.18, 9285.16, 9291.71, 9288.75,
        9287.43, 9325.02, 9106.68, 9127.95, 9420.67, 9139.51, 9150.35, 9208.18,
        9206.41, 8825.17, 8858.32, 8857.9, 8855.92, 8849.67, 8846.57, 8848.46,
        8848.04, 8869.35, 8810.53, 8609.35, 8682.15, 8851.37, 8777.21, 9084.48,
        9532.59, 9077.91, 9047.28, 9616.72, 9796.63, 9712.18, 10230.58,
        10333.21, 10512.5, 10246.28, 10085.22, 10086.22, 10085.84, 10086.28,
        10332.87, 10364.43, 9683.83, 9653.14, 9653.26, 9651.56, 9601.61,
        9602.49, 9342.44, 9159.05, 9478.59, 9250.83, 8813.47, 9068.74, 9254.67,
        9238.82, 8951.94, 8883.45, 8884.42, 8883.96, 8774.94, 8749.75, 8703.11,
        8238.79, 8125.12, 8030.25, 7893.01, 7949.14, 7768.86, 7997.64, 8144.54,
        8425.43, 8435.57, 8288.75, 8367.6, 8280.49, 8316.54, 8527.16, 8319.34,
        8483.28, 8768.23, 8919.46, 8966.97, 8975.54, 9135.89, 9634.93, 9539.04,
        9679.71, 9673.83, 9476.76, 9302.88, 9304.18, 9302.69, 9302.94, 9205.75,
        9147.25, 9148.9, 9150.17, 9066.61, 9354.38, 9349.07, 9287.02, 9139.84,
        9261.17, 9174.33, 8942.84, 8870.88, 8853.52, 8853.12, 8853.68, 8427.28,
        8399.99, 8395.14, 8302.31, 8191.84, 8121.43, 8121.39, 7938.01, 8285.11,
        7821.99, 7842.72, 7840.93, 7840.35, 7835.39, 7836.3, 7830.21, 7829.35,
        7833.08, 7833.09, 8243.14, 7969.86, 8154.32, 7679.37, 7622.19, 7532.49,
        7578.74, 7671.75, 7700.27, 7771.9, 7783.13, 7604.17, 7525.25, 7525.88,
        7595.07, 8049.12, 7901.85, 7401.77, 7526.8, 7526.44, 7526.87, 7484.53,
        7340.59, 7040.92, 6858.23, 6850.1, 6684.42, 6627.07, 6625.43, 6620.59,
        6620.35, 6621.93, 6120.96, 6202.95, 6284.46, 6577.42, 6602.08, 6799.66,
        6547.01, 6799.54, 6969.55, 6972.71, 6850.58, 6792.43, 6374.16, 6347.98,
        6346.84, 6347.37, 6347.58, 6418.54, 6764.92, 6810.94, 7010.57, 7210.28,
        7217.3, 7171.05, 6951.55, 6951.03, 6950.49, 6951.08, 6952.63, 7226.05,
        7775.57, 7786.16, 8548.69, 8788.85, 8609.21, 8555.03, 8462.45, 8406.27,
        8665.4, 8339.84, 8339.46, 8343.19, 8821.52, 8807.71, 8618.61, 8452.6,
        8449.24, 8428.51, 8388.65, 8528.92, 8605.34, 8613.12, 8648.8, 8972.09,
        8736.91, 9271.03, 9370.54, 9623.77, 9826.49, 9628.33, 9432.35, 9481.62,
        9480.3, 9454.16, 9368.83, 9369.03, 9371.84, 9336.03, 9315.57, 9289.03,
        9452.72, 8979.37, 8969.14, 8969.55, 8970.34, 8931.92, 9047.61, 9136.96,
        9106.71, 8940.31, 8945.29, 8838.9, 8649.3, 8334.82, 8337.82, 8731.48,
        8960.87, 8908.99, 9152.43, 8611.1, 8552.42, 8551.0, 8551.51, 8757.29,
        8660.87, 8872.86, 9180.48, 8938.96, 9896.11, 10206.64, 9858.0, 9894.14,
        9799.38, 9693.75, 9632.16, 9622.16, 9581.82, 9538.71, 9360.34, 9305.82,
        9588.29, 9543.08, 9557.86, 9780.04, 9738.69, 9903.59, 9618.44, 9573.51,
        9573.51, 9573.85, 9460.19, 9354.2, 9495.34, 9544.27, 9419.48, 9256.01,
        9239.77, 9239.88, 9226.57, 9531.63, 9415.57, 10092.47, 10752.31,
        10448.31, 10696.32, 11007.79, 10868.88, 10749.73, 10766.37, 10697.84,
        10810.82, 11094.23, 11288.04, 10628.57, 10208.76, 10253.64, 10246.28,
        10253.35, 10250.29, 10241.42, 10012.84, 9956.03, 10059.28, 9934.28,
        9981.74, 9977.74, 10017.74, 9781.34, 9736.76, 9738.56, 10070.18,
        10279.79, 10080.09, 10050.42, 10167.17, 9909.95, 9910.65, 10480.8,
        10329.89, 10421.19, 10241.74, 10224.53, 10274.49, 10372.76, 10182.65,
        10151.64, 10031.79, 10048.32, 10001.44, 9995.84, 10287.03, 10229.41,
        10110.4, 9462.3, 9361.9, 9363.81, 9361.59, 9505.95, 9465.47, 9507.23,
        9585.78, 9553.79, 9484.0, 9463.53, 9562.25, 9421.38, 9254.51, 9214.81,
        9200.35
      ]
    },
    2023: {
      TIMESTAMP: [
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31',
        '2024-01-01'
      ],
      BTC: [
        10000.0, 10049.21, 10107.49, 10094.6, 10185.13, 10191.9, 10264.83,
        10251.51, 10267.75, 10414.02, 10549.16, 10626.83, 11448.54, 12014.78,
        12705.15, 12657.53, 12823.74, 12851.88, 12593.67, 12750.72, 13676.74,
        13776.37, 13726.01, 13876.64, 13773.86, 13880.8, 13924.11, 13926.8,
        13908.28, 14385.43, 13758.28, 14031.87, 14354.31, 14258.47, 14176.66,
        14165.68, 13909.44, 13848.81, 14060.24, 13878.69, 13175.8, 13056.99,
        13210.31, 13192.16, 13098.85, 13430.14, 14618.88, 14523.96, 14938.21,
        14906.83, 14824.42, 14953.58, 14759.63, 14588.08, 14446.09, 13994.39,
        13993.69, 14210.21, 14213.65, 14019.5, 14243.82, 14200.85, 13510.82,
        13501.75, 13578.64, 13579.01, 13380.47, 13196.78, 12313.9, 12164.8,
        12307.84, 13136.39, 14648.87, 14949.85, 14745.7, 15121.29, 16653.71,
        16288.82, 17016.64, 16919.38, 16967.17, 16469.65, 17077.9, 16523.42,
        16613.78, 16972.69, 16403.53, 16475.19, 17184.03, 16886.93, 17293.52,
        17261.82, 17012.36, 16827.26, 17061.66, 17054.41, 16953.07, 16921.66,
        16910.65, 17173.11, 17913.16, 18318.56, 18062.87, 18338.13, 18422.41,
        18371.36, 18359.28, 17857.65, 18383.38, 17599.73, 17099.08, 16504.06,
        16859.51, 16701.19, 16623.8, 17089.49, 17128.84, 17786.77, 17762.64,
        17675.06, 17782.84, 16960.41, 17394.36, 17618.45, 17420.37, 17910.56,
        17453.8, 17395.75, 16738.32, 16693.85, 16748.78, 16284.8, 16152.2,
        16276.39, 16282.95, 16530.76, 16342.01, 16545.04, 16277.49, 16265.04,
        16383.77, 16194.59, 16265.56, 16461.01, 15948.69, 16012.78, 16152.95,
        16223.95, 17003.03, 16760.19, 16790.04, 16454.39, 16270.96, 16502.15,
        16386.31, 16484.7, 15562.61, 16435.41, 15904.71, 16067.22, 16035.95,
        15662.16, 15674.64, 15695.3, 15640.86, 15153.59, 15513.35, 15961.98,
        16082.82, 15981.19, 16194.7, 17077.52, 18228.24, 18115.2, 18548.83,
        18470.84, 18462.09, 18296.63, 18513.87, 18223.03, 18406.67, 18447.44,
        18508.55, 18553.14, 18837.27, 18655.25, 18423.37, 18260.16, 18342.95,
        18294.78, 18264.24, 18369.16, 18512.74, 18382.08, 18951.35, 18296.66,
        18331.68, 18353.24, 18223.53, 18049.75, 18081.91, 18046.31, 18113.55,
        18042.7, 18167.12, 17651.22, 17678.58, 17841.73, 17676.07, 17730.36,
        17766.89, 17648.04, 17680.72, 17707.36, 17666.19, 17696.01, 17588.49,
        17608.45, 17614.14, 17663.27, 18018.48, 17917.95, 17835.08, 17816.65,
        17811.76, 17746.95, 17818.73, 17664.74, 17499.14, 16222.73, 15781.29,
        15770.4, 15864.51, 15817.62, 15621.5, 16013.73, 15810.19, 15770.4,
        15756.01, 15791.75, 15774.88, 16729.36, 16488.89, 15770.37, 15595.06,
        15653.89, 15707.81, 15601.53, 15597.46, 15585.32, 15933.83, 15680.65,
        15682.36, 15648.35, 15222.4, 15719.57, 15884.31, 16101.6, 16207.62,
        16076.69, 16035.52, 16265.61, 16457.68, 16401.08, 16094.87, 16091.46,
        16078.02, 16034.83, 15908.32, 15833.13, 15939.96, 16349.67, 16274.75,
        16349.24, 16946.16, 16678.68, 16587.69, 16798.65, 16606.47, 16930.91,
        16918.68, 16899.3, 16698.15, 16614.12, 16212.36, 16194.76, 16240.04,
        16251.89, 16404.86, 17256.76, 17200.68, 17156.29, 17341.54, 17954.1,
        18156.89, 18182.49, 20166.03, 20674.31, 20947.2, 20717.94, 20462.99,
        20643.27, 21005.28, 20863.72, 20918.53, 21355.03, 21057.55, 20960.32,
        21282.24, 21168.08, 21255.43, 21356.13, 21659.74, 22122.76, 22608.87,
        22330.55, 22490.2, 22073.52, 21553.4, 22823.71, 21922.4, 22120.45,
        22096.96, 22384.06, 22653.72, 22019.23, 22665.88, 22580.58, 22808.89,
        22834.84, 22791.41, 22446.36, 22917.22, 22874.57, 22816.08, 23427.31,
        23817.84, 24248.61, 25392.56, 26805.82, 26491.93, 26110.1, 26790.76,
        26572.63, 26502.01, 24990.45, 24956.5, 26005.0, 26070.77, 25459.48,
        25610.9, 25251.7, 25731.83, 25624.42, 26397.99, 26602.45, 26512.01,
        26479.24, 26014.02, 26439.45, 25706.95, 26223.77, 25801.43, 25277.82,
        25502.8, 25578.56
      ],
      ETH: [
        10000.0, 10040.47, 10158.17, 10137.95, 10477.53, 10456.14, 10614.37,
        10559.45, 10612.79, 11042.75, 11155.95, 11280.18, 11892.27, 12149.86,
        12966.67, 13020.93, 13208.64, 13159.9, 12802.16, 12970.38, 13810.05,
        13617.93, 13615.84, 13605.48, 13059.5, 13258.36, 13390.71, 13316.8,
        13080.73, 13735.48, 13074.52, 13294.9, 13692.57, 13840.98, 13937.7,
        14044.63, 13662.3, 13650.45, 13921.79, 13801.33, 12857.43, 12610.97,
        12843.14, 12651.53, 12469.62, 12995.77, 13925.72, 13761.12, 14201.18,
        14127.98, 14091.02, 14152.63, 13820.06, 13664.84, 13769.48, 13416.63,
        13306.53, 13656.22, 13635.74, 13479.92, 13848.39, 13783.62, 13099.68,
        13054.4, 13088.15, 13119.84, 12985.24, 12920.97, 11975.87, 11905.74,
        12202.84, 13077.47, 14047.13, 14223.53, 13779.53, 13983.5, 14815.3,
        14790.14, 15089.43, 14608.9, 14995.63, 14435.19, 15118.84, 14573.46,
        14528.05, 14847.82, 14291.16, 14801.01, 14968.56, 14917.98, 15268.02,
        15242.41, 14992.71, 15100.56, 15607.84, 15966.97, 15628.22, 15614.85,
        15473.72, 15597.96, 15916.8, 15834.56, 15992.55, 16781.47, 17595.63,
        17516.15, 17776.81, 17385.99, 17493.08, 16435.53, 16243.26, 15424.82,
        15695.12, 15563.76, 15374.44, 15613.01, 15591.2, 15928.34, 15834.31,
        15913.29, 15820.18, 15265.07, 15665.84, 15941.05, 15643.78, 16624.32,
        15845.18, 15946.4, 15441.37, 15415.29, 15402.42, 14961.53, 15068.3,
        15063.1, 15047.98, 15241.58, 15243.42, 15217.66, 15121.03, 15150.95,
        15201.11, 15089.53, 15203.62, 15502.56, 15042.63, 15108.67, 15267.57,
        15299.17, 15939.97, 15818.85, 15920.57, 15628.16, 15611.68, 15935.44,
        15811.65, 15897.25, 15107.75, 15738.51, 15296.25, 15461.36, 15369.49,
        14662.33, 14662.07, 14571.28, 14503.39, 13793.73, 13923.41, 14380.84,
        14467.04, 14399.22, 14490.43, 14923.56, 15827.12, 15637.25, 15803.19,
        15674.3, 15885.11, 15540.88, 15766.9, 15280.22, 15479.08, 16172.97,
        16073.9, 16235.33, 16346.41, 16231.31, 15957.27, 15621.28, 15614.85,
        15557.17, 15605.56, 15685.58, 15708.31, 15644.95, 16646.37, 16104.58,
        16122.89, 16162.92, 15965.64, 15894.65, 15784.81, 15837.64, 15825.94,
        15751.37, 15746.02, 15446.98, 15547.06, 15654.97, 15520.22, 15683.4,
        15714.52, 15514.61, 15519.3, 15476.83, 15403.94, 15363.88, 15286.9,
        15368.32, 15299.77, 15279.71, 15527.66, 15512.36, 15479.24, 15437.53,
        15462.95, 15374.91, 15430.34, 15284.97, 15149.87, 14172.6, 13897.33,
        13931.27, 14076.48, 13936.78, 13540.26, 14012.75, 13831.19, 13826.91,
        13781.68, 13855.94, 13801.93, 14462.1, 14224.19, 13790.97, 13598.95,
        13683.63, 13674.95, 13579.14, 13641.26, 13640.15, 13798.0, 13675.93,
        13672.51, 13538.51, 12940.43, 13351.41, 13433.01, 13626.71, 13789.73,
        13676.69, 13548.78, 13727.52, 13726.95, 13568.02, 13257.54, 13332.01,
        13296.84, 13286.38, 13266.98, 13274.75, 13361.01, 13796.07, 13936.27,
        14002.32, 14500.64, 13888.29, 13857.11, 13781.94, 13507.58, 13751.67,
        13669.84, 13636.22, 13183.47, 13109.48, 13086.66, 12873.56, 12953.49,
        13011.17, 13001.12, 13392.13, 13059.72, 13079.97, 13076.71, 13445.21,
        13630.45, 13932.5, 14925.49, 14994.3, 14987.45, 15108.73, 14855.52,
        14854.44, 15090.76, 15125.88, 15123.22, 15410.28, 15013.69, 15308.64,
        15531.09, 15763.16, 15909.61, 15712.49, 15809.31, 17506.35, 17402.79,
        17095.98, 17228.13, 17210.26, 16609.42, 17161.27, 16451.19, 16406.94,
        16361.88, 16559.51, 16920.92, 16238.41, 17262.01, 17270.79, 17337.82,
        17412.64, 17361.24, 16889.64, 17130.33, 16962.56, 17082.1, 17434.64,
        18039.72, 18433.3, 18626.43, 19074.51, 18674.25, 19644.23, 19761.52,
        19674.24, 19740.95, 18586.46, 18292.43, 18899.13, 19352.73, 18650.92,
        18633.68, 18549.58, 18498.17, 18206.32, 18325.96, 18765.87, 19336.35,
        19197.89, 18890.01, 19102.02, 18625.41, 19768.87, 19705.93, 19074.1,
        19125.92, 19016.83
      ],
      BNB: [
        10000.0, 9939.1, 9987.82, 9987.82, 10462.85, 10430.36, 10548.1,
        10596.82, 11035.31, 11100.27, 11238.31, 11384.48, 11648.38, 11900.11,
        12391.37, 12293.95, 12168.09, 12212.74, 11786.43, 11932.6, 12310.18,
        12228.97, 12245.23, 12415.74, 12273.64, 12379.19, 12362.96, 12468.51,
        12375.14, 12915.13, 12423.86, 12691.82, 12874.53, 13260.25, 13422.65,
        13438.89, 13321.13, 13260.25, 13495.72, 13300.84, 12350.78, 12391.37,
        12618.74, 12833.94, 11827.02, 12017.86, 12829.86, 12480.69, 12732.44,
        12842.05, 12708.08, 12736.49, 12630.92, 12618.74, 12525.36, 12220.86,
        12245.23, 12517.24, 12367.01, 12289.87, 12261.46, 12188.37, 11741.78,
        11697.11, 11733.65, 11680.87, 11676.8, 11668.69, 11213.95, 11246.44,
        11120.58, 11550.94, 12553.78, 12549.73, 12403.56, 13430.76, 13670.3,
        13483.54, 13743.39, 13654.07, 13617.52, 13053.17, 13329.26, 13061.3,
        13065.35, 13345.49, 12533.49, 12675.59, 12748.67, 12817.68, 12870.48,
        12801.45, 12704.0, 12476.64, 12626.86, 12773.03, 12683.72, 12622.81,
        12610.63, 12712.13, 12809.58, 13166.85, 12967.91, 13166.85, 13377.98,
        13552.57, 14198.11, 13832.71, 13922.02, 13183.09, 12882.66, 13016.63,
        13487.61, 13418.58, 13455.12, 13686.56, 13394.22, 13503.84, 13154.67,
        13085.66, 13710.92, 13341.44, 13105.95, 13264.3, 13126.26, 13280.53,
        13101.9, 13101.9, 12724.31, 12647.17, 12801.45, 12444.17, 12476.64,
        12651.23, 12663.41, 12744.62, 12630.92, 12732.44, 12602.5, 12545.67,
        12594.4, 12452.28, 12561.91, 12724.31, 12411.68, 12358.91, 12456.35,
        12448.23, 12740.55, 12622.81, 12659.35, 12431.99, 12411.68, 12484.77,
        12436.04, 12423.86, 11161.17, 11453.51, 10483.14, 10682.09, 10600.89,
        9715.79, 9545.25, 9370.67, 9801.05, 9593.98, 9602.11, 9711.73, 9971.56,
        9878.19, 9853.83, 9926.91, 10142.1, 9752.33, 9935.02, 9585.87, 9707.66,
        9589.92, 9650.83, 9374.74, 9464.06, 9784.8, 10056.83, 10020.29,
        10004.05, 9857.88, 9703.61, 9488.42, 9561.51, 9585.87, 9512.79, 9898.48,
        10101.5, 9898.48, 10328.86, 10000.0, 10211.11, 9930.97, 9894.43,
        9768.56, 9788.87, 9861.96, 9890.37, 9817.29, 9821.34, 9683.3, 9650.83,
        9711.73, 9756.38, 9833.52, 9833.52, 9817.29, 9809.16, 10004.05, 9796.98,
        9788.87, 9805.11, 9870.06, 9870.06, 9821.34, 9959.38, 9898.48, 9792.93,
        9711.73, 9764.51, 9756.38, 9760.44, 9622.39, 9415.34, 8976.85, 8777.9,
        8794.14, 8810.39, 8562.72, 8473.4, 8777.9, 8834.75, 8850.99, 8786.03,
        8863.17, 8871.3, 9220.45, 9062.12, 8810.39, 8656.09, 8700.76, 8692.64,
        8725.13, 8704.82, 8733.25, 8822.57, 8708.89, 8708.89, 8635.81, 8371.9,
        8595.21, 8635.81, 8635.81, 8712.95, 8729.18, 8790.08, 8798.21, 8814.45,
        8684.53, 8558.67, 8578.96, 8534.31, 8518.05, 8522.13, 8591.14, 8599.26,
        8729.18, 8729.18, 8733.25, 8891.58, 8708.89, 8668.27, 8668.27, 8566.77,
        8676.4, 8615.5, 8574.9, 8359.72, 8477.46, 8375.96, 8335.36, 8363.77,
        8384.08, 8501.82, 8737.31, 8578.96, 8558.67, 8538.36, 8635.81, 8721.07,
        8846.93, 9334.13, 9212.34, 9037.75, 9086.48, 9086.48, 9159.56, 9248.88,
        9261.06, 9183.92, 9220.45, 9362.56, 9350.38, 9654.88, 9922.84, 10349.15,
        9991.87, 10008.11, 10081.19, 10203.0, 10194.87, 10089.32, 9805.11,
        9841.65, 10263.9, 9861.96, 9935.02, 9914.73, 9930.97, 10324.81, 9135.2,
        9593.98, 9496.53, 9447.83, 9480.3, 9435.65, 9196.08, 9317.89, 9232.63,
        9228.57, 9265.12, 9281.35, 9281.35, 9415.34, 9358.49, 9334.13, 9427.52,
        9675.19, 9707.66, 9732.02, 9939.1, 10353.22, 10288.27, 10300.45,
        9967.51, 9951.28, 9788.87, 9760.44, 10235.47, 10527.81, 11019.08,
        11047.49, 11035.31, 10706.45, 10856.67, 11940.7, 12898.9, 13207.45,
        12622.81, 12809.58, 12618.74
      ],
      SOL: [
        10000.0, 10020.11, 11336.86, 13437.24, 13618.24, 13497.57, 13437.24,
        13105.58, 13778.95, 16392.17, 16372.06, 16271.5, 16472.44, 18160.89,
        23738.91, 23266.47, 23769.07, 23306.69, 21256.42, 21537.8, 25346.92,
        24934.82, 24331.83, 24502.78, 23105.76, 24321.78, 24432.39, 24231.28,
        23980.07, 26402.22, 23879.51, 24161.07, 25065.54, 24703.71, 24824.38,
        24743.94, 23698.69, 23115.82, 23769.07, 23266.47, 20372.06, 20100.73,
        20884.54, 21688.63, 20542.82, 21809.3, 23889.57, 22522.71, 23296.64,
        23708.74, 25326.81, 26362.0, 24975.04, 24231.28, 23949.9, 22924.76,
        22321.78, 23176.15, 22814.32, 22251.39, 22522.71, 22171.12, 21487.7,
        20824.2, 21156.04, 20814.32, 20150.83, 18462.38, 17216.19, 18181.0,
        18040.4, 19929.79, 20643.38, 20995.15, 19336.86, 19728.85, 21296.64,
        21638.35, 22110.79, 22532.77, 22583.05, 21547.85, 22151.01, 20653.44,
        20442.45, 21135.93, 19909.68, 20542.82, 21226.25, 20532.77, 21507.63,
        21276.53, 20653.44, 20552.88, 21055.48, 21025.32, 20693.66, 20593.1,
        20040.4, 20462.56, 20944.87, 23316.75, 23799.24, 24512.83, 24834.44,
        24241.33, 25548.03, 24914.7, 24864.6, 22995.15, 22341.89, 21346.92,
        21970.01, 21588.07, 21467.58, 21809.3, 21316.75, 22362.0, 23377.08,
        23166.09, 23256.42, 22080.62, 22372.06, 22281.56, 21769.07, 22964.98,
        22020.29, 22271.5, 20753.99, 20713.77, 21145.98, 20271.5, 20814.32,
        21005.2, 21055.48, 21216.19, 20753.99, 21085.65, 20552.88, 20462.56,
        20341.89, 19759.02, 19698.69, 20140.95, 19336.86, 19326.81, 19477.46,
        20472.61, 21055.48, 20683.6, 21346.92, 20874.48, 20663.49, 21427.36,
        21246.36, 22201.11, 20090.68, 20492.55, 18673.55, 19025.32, 17537.8,
        15819.18, 15588.07, 15276.53, 14995.15, 14482.49, 14844.32, 15427.19,
        15809.12, 15457.35, 16050.45, 16603.16, 17346.92, 16703.71, 17125.69,
        16633.32, 17105.58, 16362.0, 16743.94, 16080.62, 17990.12, 19236.31,
        18532.77, 19608.19, 19165.92, 19557.91, 18924.76, 20402.22, 21608.19,
        22020.29, 21507.63, 21377.08, 22120.84, 22090.68, 25909.68, 26462.56,
        27477.64, 27738.91, 26764.05, 25628.3, 26522.89, 25638.35, 25879.69,
        25306.69, 24804.27, 23527.92, 23276.53, 25457.53, 25256.42, 25025.32,
        25387.14, 24221.22, 23899.62, 23789.19, 23336.86, 22834.44, 22924.76,
        22774.1, 23367.03, 23156.04, 24221.22, 24472.61, 24733.88, 24693.66,
        25115.82, 24412.28, 25206.32, 23959.95, 22954.93, 22110.79, 21507.63,
        21899.62, 21909.68, 21356.97, 20442.45, 21638.35, 21045.43, 20462.56,
        20422.33, 20904.65, 20623.27, 21959.95, 20944.87, 19889.57, 19356.97,
        19598.13, 19608.19, 19437.42, 20321.78, 19688.63, 20030.34, 19698.69,
        19588.07, 18432.39, 17658.46, 18100.73, 18512.66, 19035.37, 19246.36,
        19165.92, 18884.54, 19909.68, 20160.89, 20392.17, 19648.41, 19567.96,
        19507.63, 19608.19, 19467.58, 19035.37, 19155.86, 19939.84, 20382.11,
        21527.74, 24502.78, 23457.53, 23678.57, 23316.75, 22874.48, 23628.3,
        23417.31, 23356.97, 22201.11, 22261.44, 22070.57, 21447.47, 21899.62,
        22140.95, 21980.07, 24261.44, 24100.73, 23748.96, 24934.82, 27467.58,
        29879.69, 29407.25, 32573.17, 30472.61, 32332.01, 33025.32, 31738.91,
        31789.19, 33125.87, 35125.87, 38130.9, 42522.89, 40231.45, 39940.02,
        42623.45, 41136.1, 41497.75, 43276.7, 43467.76, 45628.47, 56905.0,
        58492.9, 57367.38, 52502.95, 57457.7, 65437.77, 58372.41, 59015.61,
        58633.68, 61025.67, 56724.0, 52080.8, 58291.96, 57126.05, 57156.21,
        58945.22, 58121.02, 55015.61, 58121.02, 59457.7, 59558.26, 60412.63,
        63176.33, 64060.86, 61236.66, 60744.29, 63045.78, 68251.74, 73900.15,
        74261.97, 74553.41, 70080.97, 68080.97, 71658.82, 76101.08, 75065.89,
        74352.3, 72563.46, 74915.23, 73196.61, 82040.93, 95045.96, 97548.56,
        109357.68, 112523.6, 120342.77, 112222.1, 107749.67, 104272.21,
        105508.34, 102322.48, 101036.08
      ],
      XRP: [
        10000.0, 9938.35, 10231.86, 10102.72, 10179.03, 9958.89, 10155.54,
        10093.92, 10044.03, 10270.03, 10308.17, 10912.82, 11024.34, 11262.11,
        11584.96, 11317.88, 11364.82, 11414.73, 11206.34, 11543.88, 12034.05,
        11840.31, 11752.28, 12521.27, 11987.08, 12142.65, 12022.3, 12042.85,
        11940.11, 12119.16, 11537.99, 11954.79, 12163.19, 12057.53, 12092.73,
        12160.25, 11708.25, 11637.79, 11831.51, 11675.96, 11053.71, 11176.97,
        11253.28, 11027.28, 10889.34, 11132.96, 11740.53, 11367.76, 11602.56,
        11584.96, 11367.76, 11655.39, 11488.11, 11587.91, 11414.73, 11059.57,
        11062.51, 11056.65, 11144.68, 11080.11, 11250.36, 11106.54, 11015.54,
        10909.88, 10810.08, 10895.2, 11191.65, 11361.88, 10977.4, 10877.6,
        10613.43, 10751.4, 10971.51, 10971.51, 10540.06, 10710.28, 11118.28,
        10983.25, 11493.97, 11047.83, 13554.44, 12353.96, 13073.07, 12400.93,
        13034.93, 13216.9, 14000.58, 15312.57, 15999.4, 15685.34, 15817.43,
        15010.26, 15271.49, 14637.49, 14790.12, 14884.06, 14743.17, 15054.29,
        14840.03, 14863.52, 15133.54, 15171.69, 14796.0, 15036.69, 15441.72,
        15294.97, 15312.57, 15051.35, 15526.85, 14567.06, 13880.24, 13087.75,
        13836.21, 13583.78, 13539.75, 13756.95, 13460.52, 13639.55, 14009.38,
        14026.98, 13891.98, 13621.95, 13645.44, 13642.49, 13504.53, 13721.75,
        13419.41, 13328.44, 12497.79, 12521.27, 12641.61, 12318.73, 12626.93,
        12456.7, 12503.65, 12579.96, 12800.1, 13111.24, 13563.24, 13748.15,
        13795.12, 13457.58, 13551.5, 13665.98, 13322.55, 13319.61, 13715.87,
        13850.89, 14194.29, 14479.0, 15315.51, 15233.34, 14939.83, 15397.69,
        15248.0, 15887.86, 14834.15, 15585.54, 15203.97, 15438.8, 15779.26,
        14954.49, 15186.37, 15391.83, 15236.26, 14047.55, 14079.83, 13894.92,
        14117.98, 14297.03, 14517.15, 14440.83, 14740.23, 14508.35, 14561.18,
        14285.29, 14399.75, 14082.75, 14150.26, 13648.35, 13918.38, 13968.29,
        13871.44, 14235.38, 14343.98, 14346.92, 14029.92, 13733.47, 13730.55,
        13798.04, 13759.89, 14003.52, 13980.04, 13827.41, 23780.44, 21085.97,
        20956.83, 22145.56, 21646.59, 22574.1, 23988.84, 23489.84, 22612.25,
        21969.45, 21576.14, 20654.51, 20871.71, 21103.6, 20956.83, 20895.2,
        20915.74, 20589.94, 20510.69, 20657.46, 20158.49, 19571.46, 18693.84,
        18497.18, 18353.38, 18259.44, 18911.04, 18808.32, 18573.5, 18570.58,
        18438.5, 18385.67, 18567.64, 17869.07, 17240.96, 14977.97, 14869.37,
        15224.52, 15917.22, 15383.03, 15195.17, 15500.43, 15198.11, 15450.54,
        15391.83, 15341.94, 15350.74, 15911.34, 15494.57, 15024.94, 14614.01,
        14640.43, 14798.92, 14913.4, 14840.03, 14751.97, 14828.29, 14828.29,
        14787.2, 14634.57, 13883.18, 14164.95, 14211.89, 14429.09, 14757.83,
        14684.46, 14458.46, 14831.23, 15077.77, 15289.09, 14919.26, 15092.43,
        14884.06, 14878.17, 14816.55, 14687.4, 14693.26, 14919.26, 15297.91,
        15154.09, 15415.31, 15022.0, 15497.49, 15656.0, 15383.03, 15450.54,
        15315.51, 15177.57, 14754.92, 14649.23, 14314.63, 14223.64, 14276.49,
        14273.55, 14308.78, 14643.37, 14417.37, 14352.78, 14928.09, 15268.54,
        15330.2, 15324.31, 16149.08, 16498.37, 16278.23, 16257.68, 16014.08,
        16025.82, 16354.54, 16973.87, 17557.96, 17898.44, 17722.33, 17986.47,
        18092.15, 19134.12, 21179.91, 20023.46, 20176.09, 19474.6, 19410.03,
        19533.29, 19515.69, 19195.75, 18476.64, 19031.38, 18053.99, 18074.53,
        17913.1, 18277.07, 17986.47, 17038.45, 18007.04, 18209.56, 18212.5,
        18215.41, 18159.67, 17643.07, 17916.04, 17857.33, 17772.22, 17957.13,
        18133.24, 18359.24, 18282.93, 18194.87, 18652.75, 18825.92, 19618.41,
        19697.66, 19448.18, 18200.75, 18133.24, 18476.64, 18573.5, 18165.53,
        18186.07, 17995.3, 17960.07, 17804.5, 18156.73, 18306.41, 18324.01,
        18183.13, 17913.1, 18896.38, 18221.3, 18567.64, 18594.07, 18180.21,
        18233.04, 17945.39
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 9984.96, 10705.07, 11806.56, 11885.51, 11824.86, 11787.13,
        11789.89, 11850.16, 13102.79, 13066.9, 13011.58, 13112.63, 13645.48,
        14608.44, 14440.71, 14583.24, 14406.16, 14006.32, 13973.28, 14246.71,
        14051.44, 13847.51, 13885.0, 13656.56, 13742.83, 13714.62, 13717.44,
        13564.13, 13833.09, 13119.79, 13207.63, 13141.78, 13040.1, 13119.68,
        13206.61, 13062.67, 13044.98, 13046.07, 12897.97, 12131.5, 12170.76,
        12171.68, 12172.47, 11805.7, 12096.27, 12750.58, 12624.11, 12796.19,
        12781.59, 12724.0, 13090.96, 12681.4, 12475.88, 12400.1, 12233.6,
        12249.2, 12250.35, 12249.68, 12103.01, 12388.54, 12303.94, 11729.11,
        11691.92, 11692.02, 11705.34, 11557.77, 11667.08, 11499.8, 11335.97,
        11335.35, 11337.42, 11615.14, 11805.03, 11665.43, 11924.03, 12969.05,
        12723.79, 13215.34, 12939.77, 12926.93, 12361.49, 12672.61, 12401.09,
        12400.76, 12542.68, 12872.12, 13393.95, 13663.84, 13547.53, 13599.81,
        13516.31, 13311.1, 13132.28, 13123.01, 13384.76, 13138.9, 13107.76,
        13068.74, 13081.48, 13565.31, 13832.11, 14035.72, 14257.72, 14400.95,
        14345.53, 14532.58, 14204.52, 14062.86, 13563.57, 13561.81, 13562.12,
        13564.34, 13538.45, 13551.14, 13590.99, 13597.83, 14048.7, 14030.82,
        13917.88, 13946.25, 13518.99, 13432.26, 13480.87, 13251.11, 13856.2,
        13293.4, 13287.54, 13285.53, 13285.06, 13285.73, 13283.8, 13313.04,
        13298.66, 13317.03, 13408.32, 13275.22, 13325.93, 13526.48, 13618.42,
        13637.34, 13491.65, 13556.22, 13786.99, 13431.63, 13422.79, 13438.56,
        13492.73, 13677.9, 13454.51, 13718.98, 13686.8, 13578.5, 13769.59,
        13656.23, 13852.07, 13339.78, 13375.34, 13230.55, 13227.97, 13213.75,
        12734.56, 12733.51, 12732.46, 12316.69, 11964.35, 11959.94, 11897.75,
        11976.26, 11910.17, 12075.21, 12544.86, 13274.78, 13200.86, 13474.68,
        13423.49, 13418.85, 13043.68, 12968.47, 12854.66, 12790.5, 13290.89,
        13019.6, 13446.9, 13395.35, 13419.76, 13101.82, 13247.19, 13712.45,
        13874.0, 13661.48, 13610.39, 13659.36, 13550.29, 14894.3, 14192.49,
        14155.59, 14503.23, 14333.76, 14594.53, 14986.15, 14853.54, 14621.65,
        14569.1, 14545.04, 14179.45, 14179.28, 14179.54, 14146.05, 14072.69,
        14180.05, 13978.7, 13908.29, 13940.39, 13744.07, 13732.03, 13675.56,
        13687.17, 13711.47, 13633.28, 13972.99, 14048.0, 14126.77, 14099.75,
        14088.72, 13884.49, 14034.36, 13642.01, 13652.25, 13648.75, 13647.26,
        13647.27, 13647.19, 13494.03, 13428.8, 13440.95, 13124.46, 13114.47,
        13105.26, 13033.82, 13050.28, 13524.97, 13286.46, 12784.66, 12588.71,
        12594.79, 12594.56, 12539.87, 12509.71, 12269.03, 12190.49, 12039.31,
        12040.23, 12016.98, 12000.73, 11940.71, 11885.68, 11984.24, 11936.28,
        11893.37, 11806.47, 11732.54, 11825.77, 11902.91, 11691.75, 11759.24,
        11735.03, 11689.01, 11637.52, 11610.17, 11476.78, 11799.54, 11903.04,
        11951.76, 12856.95, 12533.93, 12605.35, 12503.62, 12381.73, 12412.29,
        12399.47, 12386.96, 12202.23, 12202.87, 12202.28, 12201.77, 12201.93,
        12202.02, 12182.7, 12913.87, 12846.85, 12744.67, 13110.06, 13872.15,
        14572.06, 14446.83, 15344.06, 15024.89, 15194.82, 15051.43, 14712.86,
        14743.05, 14913.28, 15430.25, 16182.89, 17230.97, 16727.28, 16646.89,
        17275.69, 17057.37, 17353.0, 16946.64, 17003.83, 17521.05, 18454.75,
        18751.12, 18546.08, 17651.32, 17293.95, 18724.51, 17551.92, 17662.14,
        17567.06, 17577.64, 17387.6, 16771.22, 17031.34, 17037.24, 17094.1,
        17199.98, 16914.45, 16414.24, 16415.08, 16265.72, 16273.97, 16400.78,
        16734.11, 17049.26, 17379.56, 18214.72, 18029.12, 17805.35, 18716.54,
        18770.94, 18823.56, 18400.77, 18679.78, 18626.57, 18887.27, 18744.58,
        18643.95, 18499.84, 18499.67, 18278.34, 18924.48, 20624.94, 20930.56,
        22393.23, 22758.55, 23668.23, 22756.24, 23428.0, 23637.2, 22631.58,
        22635.92, 22672.3
      ]
    },
    2024: {
      TIMESTAMP: [
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31',
        '2024-08-01',
        '2024-08-02',
        '2024-08-03',
        '2024-08-04',
        '2024-08-05',
        '2024-08-06',
        '2024-08-07',
        '2024-08-08',
        '2024-08-09',
        '2024-08-10',
        '2024-08-11',
        '2024-08-12',
        '2024-08-13',
        '2024-08-14',
        '2024-08-15',
        '2024-08-16',
        '2024-08-17',
        '2024-08-18',
        '2024-08-19',
        '2024-08-20',
        '2024-08-21',
        '2024-08-22',
        '2024-08-23',
        '2024-08-24',
        '2024-08-25',
        '2024-08-26',
        '2024-08-27',
        '2024-08-28',
        '2024-08-29',
        '2024-08-30',
        '2024-08-31',
        '2024-09-01',
        '2024-09-02',
        '2024-09-03',
        '2024-09-04',
        '2024-09-05',
        '2024-09-06',
        '2024-09-07',
        '2024-09-08',
        '2024-09-09',
        '2024-09-10',
        '2024-09-11',
        '2024-09-12',
        '2024-09-13',
        '2024-09-14',
        '2024-09-15',
        '2024-09-16',
        '2024-09-17',
        '2024-09-18',
        '2024-09-19',
        '2024-09-20',
        '2024-09-21',
        '2024-09-22',
        '2024-09-23',
        '2024-09-24',
        '2024-09-25',
        '2024-09-26',
        '2024-09-27',
        '2024-09-28',
        '2024-09-29',
        '2024-09-30',
        '2024-10-01',
        '2024-10-02',
        '2024-10-03',
        '2024-10-04',
        '2024-10-05',
        '2024-10-06',
        '2024-10-07',
        '2024-10-08',
        '2024-10-09',
        '2024-10-10',
        '2024-10-11',
        '2024-10-12',
        '2024-10-13',
        '2024-10-14',
        '2024-10-15',
        '2024-10-16',
        '2024-10-17',
        '2024-10-18',
        '2024-10-19',
        '2024-10-20',
        '2024-10-21',
        '2024-10-22',
        '2024-10-23',
        '2024-10-24',
        '2024-10-25',
        '2024-10-26',
        '2024-10-27',
        '2024-10-28',
        '2024-10-29',
        '2024-10-30',
        '2024-10-31',
        '2024-11-01',
        '2024-11-02',
        '2024-11-03',
        '2024-11-04',
        '2024-11-05',
        '2024-11-06',
        '2024-11-07',
        '2024-11-08',
        '2024-11-09',
        '2024-11-10',
        '2024-11-11',
        '2024-11-12',
        '2024-11-13',
        '2024-11-14',
        '2024-11-15',
        '2024-11-16',
        '2024-11-17',
        '2024-11-18',
        '2024-11-19',
        '2024-11-20',
        '2024-11-21',
        '2024-11-22',
        '2024-11-23',
        '2024-11-24',
        '2024-11-25',
        '2024-11-26',
        '2024-11-27',
        '2024-11-28',
        '2024-11-29',
        '2024-11-30',
        '2024-12-01',
        '2024-12-02',
        '2024-12-03',
        '2024-12-04',
        '2024-12-05',
        '2024-12-06',
        '2024-12-07',
        '2024-12-08',
        '2024-12-09',
        '2024-12-10',
        '2024-12-11',
        '2024-12-12',
        '2024-12-13',
        '2024-12-14',
        '2024-12-15',
        '2024-12-16',
        '2024-12-17',
        '2024-12-18',
        '2024-12-19',
        '2024-12-20',
        '2024-12-21',
        '2024-12-22',
        '2024-12-23',
        '2024-12-24',
        '2024-12-25',
        '2024-12-26',
        '2024-12-27',
        '2024-12-28',
        '2024-12-29',
        '2024-12-30',
        '2024-12-31',
        '2025-01-01'
      ],
      BTC: [
        10000.0, 10301.02, 10644.57, 10116.38, 10487.51, 10404.55, 10383.54,
        10379.8, 11122.18, 10945.65, 11099.16, 10980.2, 10059.66, 10173.41,
        10003.11, 10087.58, 10224.37, 10109.86, 9786.26, 9864.92, 9887.28,
        9852.22, 9426.12, 9339.96, 9436.35, 9455.35, 9916.8, 9975.78, 9899.7,
        10201.91, 10253.18, 10094.23, 10158.37, 10223.55, 10183.03, 10074.24,
        10058.42, 10222.94, 10450.28, 10713.39, 11186.7, 11317.63, 11391.01,
        11837.11, 11718.89, 12256.28, 12221.25, 12300.16, 12236.89, 12362.14,
        12290.35, 12345.31, 12189.71, 12176.67, 12018.59, 12200.47, 12234.88,
        12934.81, 13463.27, 14500.23, 14488.19, 14787.79, 14597.29, 14867.63,
        16074.9, 15002.64, 15598.64, 15879.21, 16115.83, 16178.05, 16190.72,
        17142.63, 16839.94, 17263.48, 16929.2, 16352.99, 15703.84, 16145.63,
        16018.79, 14821.84, 16016.4, 15474.55, 14913.66, 15322.08, 15843.49,
        16638.32, 16623.93, 16432.48, 16765.63, 16519.52, 16498.91, 16794.51,
        16496.75, 15574.83, 15651.52, 16076.77, 16003.76, 16341.57, 16375.64,
        17018.39, 16405.38, 16689.91, 16600.75, 15867.65, 15214.54, 15459.91,
        15017.31, 15093.66, 14598.45, 15026.72, 15148.06, 15290.29, 15388.94,
        15861.65, 15704.25, 15167.82, 15287.79, 15102.96, 14969.99, 14944.21,
        15119.07, 14338.1, 13696.99, 14039.52, 14856.98, 15053.74, 15111.98,
        15025.76, 14873.19, 14494.57, 14974.84, 14400.63, 14413.88, 14512.32,
        14854.04, 14602.91, 15645.91, 15480.89, 15800.98, 15838.94, 15704.43,
        16468.12, 16617.54, 16361.75, 16017.48, 16278.15, 16358.84, 16224.2,
        16468.31, 16237.3, 16015.95, 16166.2, 15975.61, 16036.16, 16074.63,
        16338.37, 16697.78, 16835.87, 16778.17, 16446.41, 16408.33, 16478.43,
        16445.68, 15940.27, 16173.51, 15830.42, 15621.7, 15665.72, 15797.29,
        15773.62, 15448.0, 15342.94, 15379.36, 15181.78, 15219.83, 15064.17,
        14210.12, 14650.52, 14417.34, 14586.63, 14285.62, 14428.08, 14885.04,
        14902.57, 14693.12, 14273.32, 13734.45, 13398.94, 13750.44, 13345.56,
        13413.94, 13710.1, 13656.79, 13568.73, 13676.03, 14071.83, 14402.38,
        15226.42, 15291.09, 15208.8, 15152.68, 15789.05, 15882.0, 16094.51,
        16005.3, 15598.99, 15449.9, 15534.14, 16074.16, 16238.39, 16094.09,
        15908.83, 15670.92, 15377.01, 15425.38, 14526.52, 14347.58, 13894.65,
        12943.35, 13299.36, 13082.53, 14750.38, 14379.23, 14418.67, 13960.95,
        13974.12, 14337.01, 13958.57, 13634.71, 13953.98, 14036.24, 14044.71,
        14018.92, 14048.97, 14476.94, 14301.49, 15165.21, 15105.81, 15259.17,
        14902.07, 14081.86, 14008.15, 14012.05, 14018.1, 13967.57, 13561.73,
        14044.71, 13693.3, 13756.25, 13276.72, 12694.44, 12767.8, 12904.11,
        13539.72, 13634.34, 13599.36, 13759.17, 14342.42, 14200.18, 14063.73,
        13718.16, 14255.33, 14360.58, 14894.27, 14951.11, 14962.96, 15117.06,
        14960.29, 15282.34, 14970.46, 15402.17, 15587.62, 15542.67, 15541.34,
        15022.52, 14380.27, 14400.09, 14410.39, 14722.26, 14681.18, 14818.26,
        14783.3, 14713.24, 14387.38, 14233.56, 14778.37, 14978.65, 14870.13,
        15725.83, 15795.3, 16041.98, 15960.1, 16140.33, 16169.77, 16360.8,
        16039.87, 16026.36, 15730.31, 16135.17, 15766.63, 15886.26, 16121.01,
        16555.88, 17194.42, 17132.28, 16678.54, 16432.44, 16431.02, 16299.92,
        16072.74, 16468.89, 17899.27, 17954.52, 18072.16, 18107.84, 19076.68,
        20819.62, 20964.61, 21230.39, 20739.33, 21583.67, 21375.51, 21192.73,
        21569.47, 21729.96, 22282.36, 23275.18, 23339.4, 23103.86, 23190.93,
        22340.09, 21726.39, 22728.89, 22635.25, 23023.56, 22827.96, 23087.42,
        22569.04, 22745.52, 23232.26, 22857.04, 23783.5, 23568.42, 23716.29,
        22950.51, 22918.67, 23950.5, 23769.3, 23954.65, 23928.71, 24439.77,
        25040.54, 25077.46, 23936.94, 23283.52, 23064.08, 22989.19, 22610.56,
        22526.41, 23374.83, 23382.9, 22675.52, 22347.96, 22558.25, 22039.75,
        21956.79, 22123.41
      ],
      ETH: [
        10000.0, 10261.29, 10370.41, 9695.0, 9979.02, 9881.21, 9835.93, 9743.36,
        10276.32, 10290.7, 11199.36, 11497.51, 10983.97, 11333.75, 11028.98,
        11051.58, 11401.37, 11115.89, 10860.56, 10950.12, 10866.36, 10823.85,
        10201.55, 9787.4, 9765.29, 9765.42, 9970.27, 9963.47, 9888.43, 10161.15,
        10396.48, 10048.26, 10097.94, 10149.98, 10103.87, 10058.86, 10087.31,
        10470.29, 10662.83, 10657.25, 10969.1, 10972.48, 10991.78, 11671.81,
        11587.46, 12185.93, 12367.88, 12260.75, 12245.32, 12660.61, 13018.94,
        13166.38, 12898.87, 13134.9, 12849.24, 13137.01, 13638.3, 14001.33,
        14276.82, 14684.24, 14606.35, 15092.55, 14964.15, 15271.08, 15863.05,
        15414.78, 16763.98, 16955.59, 17042.95, 17149.32, 16809.16, 17882.2,
        17363.47, 17509.94, 17046.15, 16396.37, 15641.09, 16025.74, 15484.24,
        14116.98, 15476.11, 15312.31, 14450.68, 14845.89, 15129.16, 15877.42,
        15847.84, 15452.76, 15679.34, 15482.39, 15414.3, 15956.76, 15404.67,
        14499.66, 14588.55, 14560.94, 14571.32, 14851.61, 15038.87, 16358.74,
        15468.36, 15554.14, 15410.92, 14150.66, 13270.83, 13876.96, 13745.69,
        13599.62, 13225.91, 13497.39, 13495.16, 13807.98, 13872.21, 14149.94,
        14135.1, 13769.3, 13890.62, 13770.18, 14266.0, 14465.68, 14135.1,
        13232.59, 13054.32, 13176.76, 13654.13, 13660.28, 13768.37, 13547.69,
        13349.21, 13052.66, 13427.02, 12772.98, 12818.83, 12889.07, 12925.57,
        12702.78, 13246.82, 12922.35, 13588.14, 13706.52, 13526.86, 15878.34,
        16765.65, 16462.98, 16753.33, 16429.7, 16430.93, 16872.28, 17091.21,
        16981.92, 16614.16, 16473.93, 16574.76, 16801.08, 16627.16, 16610.51,
        16768.23, 16928.11, 16757.41, 16243.65, 16162.91, 16299.39, 16153.84,
        15388.8, 15700.49, 15306.11, 15320.31, 15696.65, 15951.54, 15491.71,
        15299.51, 15636.78, 15472.01, 15489.78, 15397.15, 15058.27, 14650.58,
        14960.36, 14860.14, 15171.89, 14833.68, 14843.99, 15169.22, 15137.96,
        15055.1, 14515.71, 13751.24, 13088.52, 13471.11, 12913.82, 13296.68,
        13450.67, 13631.88, 13640.85, 13747.67, 14031.82, 14269.08, 15266.33,
        15072.58, 14925.37, 15094.22, 15422.83, 15448.58, 15533.39, 15158.36,
        15308.74, 14635.55, 13913.67, 14417.53, 14397.89, 14329.93, 14636.15,
        14414.82, 14257.87, 14107.83, 13093.54, 12789.73, 11966.78, 10782.57,
        10881.98, 10359.11, 11903.04, 11422.69, 11457.72, 11287.12, 11853.14,
        11928.62, 11737.89, 11326.2, 11411.69, 11468.75, 11634.53, 11529.91,
        11372.53, 11564.99, 11536.81, 12132.47, 12097.04, 12181.63, 11810.42,
        10815.98, 11132.72, 11096.51, 11104.94, 11064.84, 10578.29, 11188.55,
        10786.1, 10826.18, 10425.97, 9712.98, 9956.3, 10082.16, 10363.06,
        10509.01, 10286.7, 10379.03, 10756.24, 10624.94, 10314.97, 10042.51,
        10277.04, 10285.65, 10867.03, 11212.46, 11323.73, 11385.46, 11633.43,
        11720.66, 11308.43, 11595.49, 11893.51, 11759.42, 11675.11, 11440.22,
        10787.59, 10471.9, 10339.85, 10642.43, 10629.64, 10690.8, 10623.92,
        10734.09, 10433.84, 10453.62, 10723.63, 10934.37, 10845.53, 11648.65,
        11405.54, 11526.04, 11485.47, 11590.01, 11653.03, 12077.79, 11769.59,
        11587.24, 11036.76, 11136.15, 10832.73, 10931.96, 11053.81, 11292.52,
        11590.75, 11692.56, 11085.16, 11048.28, 10952.54, 10815.12, 10577.77,
        10703.45, 12024.54, 12666.81, 13007.33, 13778.83, 13998.59, 14690.71,
        14455.96, 13978.55, 13574.99, 13629.9, 13772.77, 13487.43, 13843.63,
        13595.52, 13515.11, 14820.56, 14542.74, 14972.36, 14765.57, 15171.41,
        14618.56, 16115.46, 15793.33, 15808.04, 16303.84, 16358.62, 15891.83,
        15968.29, 16889.62, 16719.57, 17689.43, 17559.13, 17629.64, 16327.76,
        15971.81, 16863.29, 17068.66, 17128.98, 16977.04, 17097.15, 17720.63,
        17071.3, 16180.75, 15207.72, 15263.06, 14641.03, 14458.41, 15208.92,
        15353.76, 15278.9, 14687.02, 14598.57, 14993.19, 14684.72, 14766.02,
        14682.27
      ],
      BNB: [
        10000.0, 10090.11, 10035.4, 10186.62, 10350.72, 10183.41, 9890.6,
        9700.77, 9842.36, 9671.81, 9864.88, 9887.39, 9498.08, 9732.96, 9758.69,
        10176.97, 10157.66, 10000.0, 10057.92, 10080.45, 10196.27, 10289.59,
        9855.22, 9565.65, 9420.85, 9401.55, 9723.31, 9839.13, 9790.86, 9977.48,
        9958.18, 9694.35, 9642.87, 9694.35, 9652.51, 9835.92, 9665.39, 9761.92,
        9909.91, 10267.06, 10408.64, 10411.85, 10321.76, 10566.28, 10444.02,
        10740.04, 11303.1, 11566.93, 11357.79, 11367.45, 11367.45, 11412.5,
        12065.64, 12380.96, 12043.12, 12290.87, 12557.93, 12918.28, 12741.32,
        13256.12, 12747.76, 13072.73, 13156.38, 13352.65, 13481.35, 12612.62,
        13725.87, 15112.62, 15424.72, 15704.65, 16750.34, 16801.81, 17265.14,
        19797.32, 19430.52, 19642.87, 18857.8, 18545.71, 17815.33, 16389.98,
        17892.55, 17799.23, 17426.01, 17960.12, 18359.09, 18938.24, 18677.62,
        18507.1, 18822.4, 19890.62, 19430.52, 19491.65, 18593.96, 17947.24,
        18121.0, 18719.45, 18680.84, 18922.14, 18725.89, 18976.85, 18751.62,
        19562.44, 19510.96, 19134.51, 17853.94, 18217.53, 17927.94, 17400.27,
        17310.18, 17728.45, 17911.86, 18301.17, 18667.97, 19462.7, 19601.05,
        19504.52, 19729.75, 19250.33, 19128.07, 19321.13, 19131.28, 18590.75,
        18008.37, 18104.9, 18893.2, 18825.63, 18963.99, 19009.02, 18703.35,
        18912.49, 19263.21, 18854.59, 19070.15, 19150.59, 19047.63, 18214.3,
        18809.54, 18301.17, 18674.41, 18597.19, 18519.96, 19050.84, 19893.83,
        19787.67, 19240.69, 19372.61, 19350.09, 19333.99, 19430.52, 19391.91,
        19192.43, 19144.16, 19131.28, 19340.44, 19372.61, 20205.94, 21956.26,
        22387.4, 22898.99, 22039.91, 21965.92, 21666.69, 19887.41, 19417.64,
        19954.96, 19359.72, 19356.51, 19575.3, 19649.31, 19504.52, 18960.76,
        19243.91, 19041.19, 18861.01, 18992.94, 18661.53, 18149.95, 18574.65,
        18426.66, 18667.97, 18252.91, 18317.27, 18812.75, 18606.84, 18658.32,
        17924.72, 16875.81, 15974.92, 17001.3, 15855.87, 16444.67, 16589.47,
        16850.08, 16888.69, 17146.09, 17168.61, 17525.76, 18725.89, 18420.22,
        18314.04, 18410.57, 19095.9, 19089.46, 19321.13, 19021.89, 18745.2,
        18455.6, 18407.36, 18722.66, 18790.23, 18735.54, 18577.88, 18738.75,
        18616.49, 18526.41, 17451.74, 16985.22, 16206.57, 15038.63, 15675.69,
        15318.55, 16698.86, 16386.75, 16846.87, 16306.32, 16550.85, 16879.04,
        16862.94, 16734.24, 16731.03, 17419.57, 17255.49, 17982.64, 18458.83,
        18336.57, 18870.67, 19066.94, 18571.44, 18597.19, 17651.24, 17084.96,
        17290.87, 17252.26, 17229.74, 17168.61, 16367.45, 16981.99, 16846.87,
        16438.23, 16148.66, 15575.95, 15820.47, 16122.93, 16705.28, 16750.34,
        17052.78, 17445.32, 17956.89, 17779.93, 18034.12, 17062.43, 17548.27,
        17770.29, 18211.08, 18281.87, 18909.28, 18931.8, 19507.73, 19559.23,
        18889.97, 19176.33, 19591.4, 19324.34, 19214.95, 18349.44, 17622.29,
        17538.62, 17532.2, 17892.55, 18143.51, 18256.12, 18272.21, 18677.62,
        18355.86, 18053.42, 18423.43, 18590.75, 18388.05, 19034.77, 19047.63,
        19401.57, 19057.29, 19269.63, 19231.04, 19456.25, 19340.44, 19160.24,
        18751.62, 19089.46, 18700.14, 18777.37, 18983.29, 19366.17, 19475.56,
        19202.08, 18552.13, 18410.57, 18291.52, 17976.2, 17752.9, 18179.88,
        19113.91, 19215.91, 19181.48, 20067.9, 20160.56, 20572.41, 20423.13,
        19799.89, 20289.59, 19909.6, 19995.18, 19837.21, 19919.58, 19700.15,
        19593.97, 20030.91, 20205.61, 21054.39, 21288.63, 20554.4, 19745.52,
        20717.52, 21209.48, 21000.01, 21084.64, 21144.81, 20635.15, 23663.46,
        23613.92, 23000.67, 23600.4, 24084.64, 23921.5, 21946.61, 21745.52,
        22834.32, 22780.26, 23383.54, 22996.16, 22979.43, 23306.98, 23216.87,
        22352.66, 21724.28, 21808.26, 21276.72, 20919.26, 22457.55, 22394.48,
        22557.93, 22234.58, 22216.88, 23278.02, 22309.87, 22667.32, 22556.96
      ],
      SOL: [
        10000.0, 10752.0, 10741.06, 9828.91, 10422.75, 9868.7, 9290.76, 8881.92,
        9748.33, 9900.53, 10272.54, 9977.11, 8979.4, 9566.3, 9616.03, 9371.32,
        9762.26, 10066.65, 9416.09, 9294.74, 9262.91, 9100.75, 8352.73, 8224.41,
        8703.86, 8680.99, 9181.33, 9319.6, 9434.0, 10037.78, 10291.45, 9718.49,
        9660.8, 9980.1, 9743.35, 9526.51, 9425.04, 9694.62, 10042.76, 10208.88,
        10731.12, 10881.32, 10697.3, 11099.17, 11144.92, 11650.24, 11142.94,
        10913.15, 10865.41, 11220.52, 11146.91, 10827.61, 10406.83, 10242.7,
        9930.37, 10357.1, 10296.43, 10909.17, 10788.81, 11521.92, 12299.8,
        12997.1, 12762.35, 13025.95, 13010.04, 12125.72, 12999.09, 14397.68,
        14334.01, 14417.58, 14108.22, 14708.04, 14757.78, 16259.81, 17200.83,
        18316.9, 18005.56, 20008.94, 19710.52, 16835.77, 18891.86, 17736.99,
        16941.19, 17382.85, 18399.47, 18996.3, 19123.63, 18598.41, 18892.85,
        19122.63, 19514.56, 19956.23, 19182.33, 18312.94, 18515.86, 18132.88,
        17348.04, 17765.83, 17762.85, 18018.48, 17307.27, 17304.28, 17183.91,
        15071.11, 14026.64, 14981.59, 13920.22, 13686.46, 13387.05, 14213.66,
        14290.26, 14838.34, 14816.46, 15719.68, 15446.12, 14614.53, 14451.4,
        13847.6, 13993.82, 13833.67, 13691.42, 12670.84, 13267.67, 13800.84,
        14261.4, 14523.02, 14439.46, 15377.49, 14922.9, 14078.38, 15214.35,
        14505.11, 14503.12, 14247.48, 14614.53, 14230.56, 15505.8, 15802.23,
        16776.07, 17111.29, 17051.61, 18462.14, 17755.88, 17656.41, 17530.08,
        16636.82, 16704.46, 16289.65, 17024.76, 16813.87, 16874.54, 16631.84,
        16527.4, 16583.1, 16242.9, 16457.76, 16990.94, 17226.69, 16965.08,
        16223.01, 15766.44, 16106.62, 15852.97, 14869.18, 15477.95, 14738.87,
        14256.44, 14470.29, 14960.71, 14306.17, 13691.42, 13399.97, 13321.4,
        13411.91, 13356.19, 12853.86, 12952.35, 13617.82, 13667.55, 14941.8,
        13929.16, 13932.15, 14626.48, 14601.6, 15390.42, 14061.46, 13176.16,
        13353.23, 14311.14, 13234.84, 13956.03, 14059.47, 14082.36, 13604.88,
        13804.82, 14145.02, 14640.39, 15794.28, 15907.67, 15517.75, 15912.65,
        16790.0, 17246.57, 18298.01, 17757.87, 17199.83, 17643.49, 17138.16,
        18227.38, 18471.09, 18246.29, 18234.35, 17838.45, 17241.62, 16658.7,
        15199.42, 14208.68, 13999.79, 13181.14, 14436.47, 14507.1, 16064.85,
        15493.88, 15384.45, 14144.03, 14337.0, 14632.43, 14358.9, 14293.24,
        13886.4, 14078.38, 14382.76, 14283.29, 14248.48, 14214.66, 14244.5,
        15103.93, 15823.13, 15977.3, 15660.99, 14670.23, 14278.32, 13908.28,
        13697.39, 13467.61, 12842.93, 13408.93, 12880.73, 13336.31, 12888.67,
        12406.24, 12614.14, 12871.77, 13374.11, 13526.29, 13188.09, 13564.1,
        13863.51, 13643.68, 13118.47, 13003.07, 13101.55, 13074.7, 14166.9,
        14635.42, 14776.67, 14471.29, 14363.85, 15254.15, 14838.34, 15551.57,
        15722.66, 15589.37, 15818.15, 15358.59, 14391.72, 14081.36, 13621.8,
        14217.64, 14221.62, 14458.37, 14369.83, 14248.48, 13846.61, 13782.95,
        14508.1, 14628.47, 14703.06, 15714.7, 15218.33, 15401.35, 15013.42,
        15347.65, 15807.21, 16581.1, 16691.52, 16807.9, 17003.87, 17779.75,
        16292.64, 17046.64, 17682.27, 17769.81, 17863.31, 17442.55, 16861.62,
        16497.56, 16502.53, 16198.14, 15718.68, 16650.74, 18804.33, 19422.04,
        19786.13, 19899.52, 20941.99, 21909.86, 21391.62, 21192.67, 21095.19,
        21708.92, 21307.05, 23350.22, 23816.75, 23420.85, 23519.33, 25357.58,
        25349.64, 25360.57, 25213.36, 23550.16, 22870.78, 23940.11, 23670.54,
        24253.43, 23715.29, 23702.37, 22325.66, 23555.14, 22781.24, 23380.06,
        23617.8, 23741.16, 23542.22, 21672.12, 21334.9, 22706.63, 22700.68,
        22248.07, 21686.05, 21815.36, 21673.11, 22258.02, 20535.15, 19583.2,
        19164.41, 18023.46, 18046.35, 19150.5, 19594.13, 19572.25, 18805.32,
        18386.53, 19415.09, 18851.09, 19016.21, 19012.23
      ],
      XRP: [
        10000.0, 10253.51, 10260.06, 9537.14, 9612.37, 9388.29, 9291.79,
        9005.56, 9486.43, 9352.31, 9939.48, 9852.79, 9280.34, 9425.91, 9574.74,
        9458.63, 9458.63, 9329.41, 9039.92, 8941.78, 8997.39, 8967.94, 8653.91,
        8428.21, 8446.2, 8420.02, 8706.26, 8678.44, 8562.31, 8771.68, 8339.88,
        8212.3, 8269.55, 8357.87, 8508.34, 8240.11, 8305.53, 8303.89, 8377.5,
        8410.2, 8601.57, 8572.14, 8585.22, 8688.26, 8603.21, 8819.11, 9172.4,
        9241.08, 9000.66, 9160.95, 9257.45, 9231.27, 8958.13, 8891.07, 8735.69,
        8925.41, 8902.52, 8994.11, 9596.01, 9370.3, 9555.13, 9824.99, 10392.54,
        10282.97, 10657.51, 9633.64, 9983.65, 10328.76, 10109.59, 10168.46,
        9816.81, 11871.13, 11239.79, 11220.15, 10945.37, 10356.56, 9913.32,
        10140.66, 10353.29, 9589.47, 10022.9, 10441.61, 9952.57, 10179.92,
        10358.2, 10521.75, 10354.93, 10049.07, 10224.08, 10279.69, 10140.66,
        10279.69, 10008.18, 9627.09, 9404.64, 9646.71, 9597.65, 9718.69,
        9692.51, 10122.67, 10181.55, 10094.87, 9947.67, 8913.97, 7778.88,
        8254.83, 8164.87, 8127.25, 8130.53, 8225.39, 8271.18, 8608.12, 8599.93,
        9138.04, 8941.78, 8599.93, 8603.21, 8572.14, 8460.91, 8395.49, 8433.11,
        8173.05, 8418.38, 8496.89, 8722.61, 8653.91, 8652.28, 8864.9, 8644.1,
        8451.1, 8521.43, 8205.76, 8274.46, 8168.14, 8248.28, 8187.77, 8470.73,
        8428.21, 8572.14, 8511.62, 8339.88, 8748.78, 8802.75, 8619.56, 8614.66,
        8758.59, 8856.73, 8662.09, 8758.59, 8662.09, 8581.94, 8500.17, 8490.35,
        8505.08, 8405.3, 8523.07, 8593.39, 8596.67, 8536.15, 8191.04, 8070.01,
        8150.14, 8123.98, 7855.74, 8034.03, 7839.39, 7765.79, 8052.02, 8002.95,
        8290.81, 8034.03, 8066.73, 8001.31, 8016.04, 7970.23, 7849.2, 7724.9,
        7780.5, 7708.54, 7782.14, 7715.08, 7723.26, 7800.13, 7809.95, 7930.99,
        7636.57, 7268.57, 6951.26, 7345.44, 6879.29, 7055.94, 7123.0, 7168.79,
        7322.54, 7759.24, 8563.95, 8637.56, 8760.23, 9432.46, 10358.2, 9308.14,
        9332.68, 9753.03, 9774.29, 9934.58, 9793.92, 9972.2, 9756.29, 9864.25,
        9826.63, 9793.92, 9854.43, 10309.13, 10371.28, 9787.37, 9174.02, 9080.8,
        8639.2, 8112.54, 8302.26, 10035.98, 10003.28, 9509.32, 9600.92, 9030.09,
        9254.17, 9421.01, 9298.33, 9169.12, 9228.01, 9250.91, 9345.76, 9764.48,
        9728.49, 9823.35, 9775.92, 9991.83, 9972.2, 9862.61, 9630.36, 9265.62,
        9327.77, 9167.49, 9262.35, 9280.34, 8920.51, 9286.89, 9208.38, 9156.03,
        8910.7, 8506.71, 8578.68, 8614.66, 8820.75, 8863.27, 8758.59, 9213.28,
        9362.12, 9744.85, 9425.91, 9514.23, 9576.38, 9509.32, 9643.44, 9605.82,
        9790.65, 9641.8, 9576.38, 9689.23, 9568.2, 9663.07, 9618.91, 10071.96,
        10474.32, 10125.94, 9733.4, 8915.6, 8585.22, 8738.96, 8704.62, 8712.8,
        8694.81, 8662.09, 8604.84, 8650.64, 8822.37, 8825.65, 8688.26, 8984.3,
        8837.1, 9007.2, 8936.87, 8917.24, 8891.07, 8967.94, 8935.23, 8750.41,
        8593.39, 8698.07, 8372.59, 8379.14, 8449.46, 8501.8, 8627.75, 8572.14,
        8354.6, 8364.41, 8339.88, 8233.57, 8231.93, 8405.3, 8925.41, 9048.09,
        9051.36, 9077.52, 9648.35, 10107.95, 11701.02, 11305.21, 12973.51,
        14699.06, 18091.27, 17448.48, 18398.77, 17837.75, 18174.69, 19705.6,
        23977.76, 24255.81, 23601.57, 23230.29, 22733.08, 24147.86, 24669.62,
        28686.62, 31275.77, 35493.96, 45029.45, 42620.23, 38776.59, 37376.53,
        40027.81, 41601.26, 42427.22, 36246.33, 38174.69, 39793.93, 38446.2,
        39507.7, 39249.28, 39275.44, 40969.92, 41769.72, 38410.22, 37355.26,
        37392.88, 36560.36, 36153.11, 36931.64, 38047.12, 37544.99, 35464.52,
        35036.0, 35937.2, 34192.03, 33773.32, 34000.67
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10136.0, 10286.04, 9845.11, 10157.35, 10059.36, 9925.61,
        9901.27, 10350.74, 10207.62, 10585.01, 10829.9, 10410.52, 10696.91,
        10543.81, 10584.79, 10688.88, 10564.78, 10168.16, 10171.31, 10190.27,
        10226.49, 10141.83, 10140.32, 10139.51, 10016.93, 10355.12, 10443.6,
        10320.29, 10701.34, 10862.28, 10533.44, 10520.14, 10493.33, 10421.43,
        10248.49, 10090.23, 10311.54, 10476.19, 10473.32, 10842.45, 10952.06,
        11012.89, 11386.85, 11267.05, 11770.62, 11925.36, 11837.61, 11742.18,
        11746.04, 11973.66, 12091.03, 11882.62, 12057.1, 11925.94, 12086.36,
        12485.23, 12775.09, 13077.72, 13948.07, 13937.25, 13770.18, 13625.4,
        13680.78, 13636.95, 12608.83, 13566.69, 13707.4, 13718.31, 13768.53,
        13681.18, 13839.03, 13476.07, 13474.49, 13916.68, 14449.33, 14316.66,
        15221.18, 15095.28, 14189.7, 14196.3, 14391.07, 14279.97, 14542.91,
        14520.67, 14548.91, 14513.52, 14368.41, 14621.03, 14506.76, 14313.26,
        14501.52, 14172.45, 13612.91, 13613.63, 13559.9, 13553.59, 13731.2,
        13755.13, 14689.53, 13995.88, 14065.91, 13992.74, 13905.93, 13543.52,
        13523.86, 13522.59, 13520.65, 13446.19, 13418.4, 13496.73, 13798.14,
        13789.6, 14271.37, 14128.25, 13591.52, 13647.67, 13546.87, 13546.39,
        13661.56, 13391.9, 12630.69, 12628.6, 12603.88, 12849.49, 12984.13,
        12969.76, 12906.02, 12702.81, 12470.55, 12492.52, 12037.15, 12090.17,
        11972.59, 11975.38, 11760.17, 12486.7, 12321.75, 12757.1, 12904.58,
        12880.86, 13489.1, 14011.41, 13792.73, 14003.08, 13679.48, 13662.21,
        13549.0, 13711.94, 13635.91, 13527.84, 13360.52, 13360.41, 13361.04,
        13248.28, 13296.91, 13734.78, 13832.65, 13952.62, 13741.96, 13728.15,
        13727.25, 13721.5, 13245.33, 13247.13, 13245.07, 13161.88, 13310.46,
        13497.43, 13253.2, 13070.72, 13085.5, 12907.41, 12920.04, 12813.17,
        12812.07, 12810.25, 12757.6, 12787.13, 13473.3, 12941.72, 12941.22,
        13312.55, 13314.67, 13532.16, 12851.08, 12813.87, 12810.53, 12814.48,
        12283.37, 12674.23, 12661.13, 12809.67, 12817.06, 12726.63, 13292.17,
        13341.96, 13782.07, 13832.11, 14026.8, 13401.73, 13519.67, 13733.86,
        14218.31, 13974.78, 13723.96, 13840.14, 13705.96, 13918.12, 14027.1,
        13836.18, 13835.37, 13851.28, 13882.88, 13546.46, 13501.1, 13499.18,
        13496.16, 13492.06, 13494.87, 13569.17, 14007.84, 13776.12, 13769.44,
        13535.42, 13555.26, 13631.46, 13444.04, 13252.35, 13238.51, 13241.2,
        13065.03, 12990.99, 12982.59, 13038.73, 12889.92, 12947.14, 12890.44,
        12934.14, 12783.84, 12624.61, 12625.16, 12625.01, 12624.93, 12574.89,
        12473.91, 12590.99, 12368.41, 12307.15, 12083.37, 12065.83, 12066.7,
        12067.83, 12140.59, 12148.86, 12123.26, 12247.59, 12337.23, 12243.34,
        12058.25, 11790.59, 11827.25, 11715.26, 11899.65, 11994.04, 12098.72,
        12156.22, 12386.86, 12467.04, 12213.0, 12548.75, 12629.78, 12584.24,
        12713.46, 12524.63, 12319.06, 12327.45, 12327.47, 12328.75, 12310.88,
        12377.68, 12211.05, 12362.09, 12276.28, 12269.69, 12352.24, 12408.38,
        12339.78, 12934.21, 12711.45, 12873.78, 12815.94, 12941.66, 12961.91,
        13278.63, 13328.7, 13380.62, 13468.57, 13821.89, 13146.45, 13176.74,
        13328.7, 13339.55, 13528.63, 13402.88, 13088.41, 13000.13, 12999.69,
        12998.49, 12997.7, 12999.18, 13301.13, 13857.21, 14179.07, 14908.04,
        15114.02, 15759.82, 15859.95, 16031.26, 15713.06, 16724.22, 18358.12,
        18093.14, 18511.8, 17861.58, 17872.75, 18473.56, 18544.47, 18649.59,
        18449.02, 18532.48, 17918.55, 18825.88, 18501.96, 18983.86, 19723.3,
        20873.68, 23270.55, 22749.59, 21885.63, 21167.16, 21799.95, 21662.58,
        21597.81, 20203.05, 20180.17, 20185.06, 20049.84, 20024.97, 19870.33,
        20024.5, 20451.27, 20152.42, 19197.7, 18620.46, 18460.67, 18458.33,
        18456.81, 19020.76, 18847.16, 18829.1, 18215.31, 17945.47, 17911.31,
        17437.74, 17610.87, 17609.97
      ]
    },
    2025: {
      TIMESTAMP: [
        '2025-01-01',
        '2025-01-02',
        '2025-01-03',
        '2025-01-04',
        '2025-01-05',
        '2025-01-06',
        '2025-01-07',
        '2025-01-08',
        '2025-01-09',
        '2025-01-10',
        '2025-01-11',
        '2025-01-12',
        '2025-01-13',
        '2025-01-14',
        '2025-01-15',
        '2025-01-16',
        '2025-01-17',
        '2025-01-18',
        '2025-01-19',
        '2025-01-20',
        '2025-01-21',
        '2025-01-22',
        '2025-01-23',
        '2025-01-24',
        '2025-01-25',
        '2025-01-26',
        '2025-01-27',
        '2025-01-28',
        '2025-01-29',
        '2025-01-30',
        '2025-01-31',
        '2025-02-01'
      ],
      BTC: [
        10000.0, 10140.57, 10366.05, 10514.24, 10527.17, 10556.9, 10911.67,
        10393.11, 10192.76, 9889.56, 10136.79, 10118.19, 10050.71, 10097.46,
        10342.62, 10665.82, 10679.17, 11168.22, 11155.48, 11061.64, 11082.39,
        11337.83, 11110.07, 11122.06, 11207.46, 11239.58, 11159.65, 10871.58,
        10831.84, 11069.99, 11241.4, 10926.3
      ],
      ETH: [
        10000.0, 10065.12, 10301.59, 10827.27, 10958.75, 10914.52, 11013.92,
        10170.12, 9979.82, 9653.76, 9801.61, 9846.67, 9702.8, 9377.45, 9687.21,
        10272.16, 9873.47, 10390.22, 9822.08, 9780.73, 9935.95, 9981.61,
        9697.63, 9947.54, 9957.63, 9983.64, 9845.14, 9494.2, 9234.34, 9378.93,
        9763.46, 9937.83
      ],
      BNB: [
        10000.0, 10092.86, 10066.9, 10181.72, 10193.71, 10110.55, 10526.06,
        9936.53, 9946.08, 9809.58, 9892.89, 9945.09, 9860.65, 9791.89, 9983.46,
        10160.62, 10111.12, 10277.43, 10068.19, 9838.39, 9753.1, 9878.05,
        9923.12, 9832.54, 9706.17, 9805.73, 9608.75, 9678.64, 9478.09, 9574.36,
        9685.34, 9656.53
      ],
      SOL: [
        10000.0, 10177.89, 10809.4, 11356.67, 11363.99, 11208.07, 11434.1,
        10658.18, 10351.07, 9727.41, 9840.95, 9852.45, 9783.39, 9580.39,
        9872.34, 10699.0, 10975.77, 11470.2, 13311.88, 13233.4, 13085.33,
        13047.66, 13607.49, 13164.86, 13316.59, 13552.03, 12931.51, 12290.06,
        11881.44, 12122.11, 12555.33, 12047.3
      ],
      XRP: [
        10000.0, 11301.23, 11572.06, 11832.31, 11686.55, 11560.03, 11648.06,
        11046.75, 11490.28, 11020.3, 11269.48, 12258.03, 12008.85, 12173.85,
        12835.77, 14826.34, 15513.76, 15724.93, 15490.18, 14676.26, 15126.51,
        15252.07, 15228.01, 15008.66, 14887.91, 15025.97, 14822.49, 14599.77,
        14791.71, 14813.35, 15079.85, 14608.91
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 9939.92, 10069.1, 10165.96, 10126.27, 10075.11, 10156.6,
        9729.86, 9703.55, 9702.88, 9703.28, 10065.44, 9978.02, 10050.52,
        10285.44, 10801.86, 11021.43, 11087.33, 11343.11, 11341.79, 11272.56,
        11261.54, 10917.11, 10772.53, 10806.02, 10690.2, 10450.63, 10285.73,
        10191.65, 10162.73, 10269.63, 10188.15
      ]
    }
  }
};
